import { Box, Typography } from "@mui/material";
import { useGetProfile } from "../../network/resolver";
import { FromStringToDate, FromStringToDateTime } from "../../../../utils/Helper";

interface HistoryLoginProps {
  idUser: number;
}

export default function HistoryLogin({ idUser }: HistoryLoginProps) {
  const { data: userProfileDataRaw, isFetched } = useGetProfile({ id: idUser });
  const userProfileData = userProfileDataRaw?.data.data;

  return (
    <Box marginTop={2}>
      <Box display={"flex"} marginTop={2}>
        <Box width={200}>
          <Typography variant="body1">Tanggal Register</Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            :{" "}
            {userProfileData?.register_date
              ? FromStringToDateTime(userProfileData?.register_date)
              : "-"}
          </Typography>
        </Box>
      </Box>
      <Box display={"flex"} marginTop={2}>
        <Box width={200}>
          <Typography variant="body1">Tanggal Login Terahkir</Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            :{" "}
            {userProfileData?.last_login_date
              ? FromStringToDateTime(userProfileData?.last_login_date)
              : "-"}
          </Typography>
        </Box>
      </Box>
      <Box display={"flex"} marginTop={2}>
        <Box width={200}>
          <Typography variant="body1">IP Login Terahkir</Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            : {userProfileData?.last_login_ip || "-"}
          </Typography>
        </Box>
      </Box>
      <Box display={"flex"} marginTop={2}>
        <Box width={200}>
          <Typography variant="body1">Lokasi Login Terahkir</Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            : {userProfileData?.last_login_location || "-"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
