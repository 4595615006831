import React from "react";
import { QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router";
import ToastContextFC from "./context/toast/ToastContext";
import router from "./router/Route";
import queryClient from "./utils/queryClient";
import UserInformationContextFC from "./context/userInformation/UserInformation";

function App() {
  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <UserInformationContextFC>
          <ToastContextFC>
            <RouterProvider router={router} />
          </ToastContextFC>
        </UserInformationContextFC>
      </QueryClientProvider>
    </React.Fragment>
  );
}

export default App;
