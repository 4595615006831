import { Box, Card, Tab, Tabs } from "@mui/material";
import { MainPadding } from "../../styles/Main.styles";
import React from "react";
import ProfileInformation from "./components/ProfileInformation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function Profile() {
  const [value, setValue] = React.useState(0);

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  return (
    <MainPadding>
      <Card>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={(e, value) => setValue(value)}
            aria-label="basic tabs example"
          >
            <Tab label="Data User" value={0} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ProfileInformation />
        </TabPanel>
      </Card>
    </MainPadding>
  );
}
