import React, { useEffect, useState } from "react";
import { useGetUserInformation } from "./network/resolver";
import IUserInformation from "./UserInformation.type";
import { TokenStorage } from "../../core/http-axios-react/http/Token";

interface UserInformationContextProps {
  get: () => IUserInformation | undefined;
  refetch: () => void;
}

const UserInformationContextDefault: UserInformationContextProps = {
  get: () => {
    return {} as IUserInformation;
  },
  refetch: () => {},
};

export const UserInformationStateContext =
  React.createContext<UserInformationContextProps>(
    UserInformationContextDefault
  );

const UserInformationContextFC: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [enabled, setEnabled] = useState(false);
  const { data: dataRawUserInformation, refetch: refetchDataUser } =
    useGetUserInformation({ enabled });
  const dataUserInformation = dataRawUserInformation?.data.data;

  // Try to fetch user information only when token present on local storage
  useEffect(() => {
    const token = TokenStorage.get();
    if (token) {
      setEnabled(true);
    }
  }, []);

  function get() {
    return dataUserInformation;
  }

  function refetch() {
    console.log(enabled)
    refetchDataUser()
  }

  return (
    <UserInformationStateContext.Provider value={{ get, refetch }}>
      {children}
    </UserInformationStateContext.Provider>
  );
};

export const useUserInformation = () =>
  React.useContext(UserInformationStateContext);

export default UserInformationContextFC;
