import styled from "@emotion/styled";
import { MainContainerModal } from "../../../../styles/Main.styles";

const Container = styled(MainContainerModal)`
  background-color: white;
  width: 70%;
  max-height: 600px;
`;

const Wrapper = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
`;

const ContentWrapper = styled.div`
  border-top: 1px solid #dedede;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: 10px;
  gap: 10px;
`

export { Container, Wrapper, ContentWrapper, ButtonContainer };
