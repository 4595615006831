import { useMutation } from "react-query";
import { doBackup, doDownloadBackup } from "./service";

export const useDoBackup = () => {
  return useMutation({
    mutationFn: () => doBackup(),
  });
};

export const useDownloadBackup = () => {
  return useMutation({
    mutationFn: ({ path }: { path: string }) => doDownloadBackup({ path }),
  });
};
