import styled from "@emotion/styled";


export const UploadImageContainer = styled.div`
  border-radius: 6px;
  height: 100%;
  width: 100px;
  min-height: 100px;
  margin-block: 10px;
  border: 1px solid #dedede;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: black;
  overflow: hidden;
  cursor: pointer;
`