import { Button, Modal } from "@mui/material";
import { useState } from "react";
import { InputMoney } from "../../../../components/input/input";
import { useAddTurnOver } from "../../network/resolver";
import { ButtonContainer, Container, Wrapper } from "../modal/UserModal.styles";
import { LoadingButton } from "../../../../components/button/Button";

interface UserModalTurnOverProps {
  username: string;
  showModal: boolean;
  closeModal(): void;
}

export default function UserModalTurnOver({
  username,
  showModal,
  closeModal,
}: UserModalTurnOverProps) {
  const [amount, setAmount] = useState<number>();
  const { mutate: turnOverMutate, isLoading } = useAddTurnOver();

  function handleCancel() {
    closeModal();
  }

  function handleAdd() {
    turnOverMutate({
      amount: amount || 0,
      username,
    });
  }

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Container style={{ width: "50%" }}>
        <Wrapper>
          <InputMoney
            label="Jumlah Turn Over"
            value={amount}
            onChange={(e) => setAmount(e)}
          />
          <ButtonContainer style={{ marginBottom: 0 }}>
            <Button onClick={handleCancel} size="small" variant="outlined">
              Cancel
            </Button>
            <LoadingButton
              onClick={handleAdd}
              text="Tambah"
              isLoading={isLoading}
            />
          </ButtonContainer>
        </Wrapper>
      </Container>
    </Modal>
  );
}
