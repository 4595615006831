import { Button, CircularProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetProfile, usePatchUser } from "../../network/resolver";
import { ButtonContainer, ContainerProfile } from "./UserProfile.styles";
import { InputSelect } from "../../../../components/input/input";

interface UserProfileProps {
  idUser: number;
}

export default function UserProfile({ idUser }: UserProfileProps) {
  const { data: userProfileDataRaw, isFetched } = useGetProfile({ id: idUser });
  const userProfileData = userProfileDataRaw?.data.data;
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [bankAccName, setBankAccName] = useState<string>("");
  const [bankAccNo, setBankAccNo] = useState<string>("");
  const [status, setStatus] = useState(true);
  const { mutate: userPatchMutate, isLoading } = usePatchUser({
    username: userProfileData?.username || "",
    email,
    phone,
    bank_acc_name: bankAccName,
    bank_acc_no: bankAccNo,
  });

  useEffect(() => {
    if (userProfileData) {
      setEmail(userProfileData.email || "");
      setPhone(userProfileData.phone || "");
      setBankAccName(userProfileData.bank_acc_name || "");
      setBankAccNo(userProfileData.bank_acc_no || "");
      setStatus(userProfileData.status === 0);
    }
  }, [userProfileData]);

  function handleOnClick() {
    userPatchMutate();
  }

  if (!isFetched) {
    return <CircularProgress />;
  }

  return (
    <ContainerProfile>
      <InputSelect
        id="status-select"
        label="Status"
        value={status}
        onChange={(e) => setStatus(e)}
        options={
          [
            { text: "Active", value: true },
            { text: "Suspend", value: false },
          ] as any
        }
      />
      <TextField
        label="Nama"
        value={userProfileData?.name}
        disabled
        fullWidth
        margin="normal"
      />
      <TextField
        label="Username"
        value={userProfileDataRaw?.data.data.username}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Ticket"
        value={userProfileData?.ticket.amount || 0}
        disabled
        fullWidth
        margin="normal"
      />
      <TextField
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Nama Rekening Bank"
        onChange={(e) => setBankAccName(e.target.value)}
        value={bankAccName}
        fullWidth
        margin="normal"
      />
      <TextField
        label="No Rekening Bank"
        onChange={(e) => setBankAccNo(e.target.value)}
        value={bankAccNo}
        fullWidth
        margin="normal"
      />
      <ButtonContainer>
        <Button variant="contained" onClick={handleOnClick}>
          {isLoading ? <CircularProgress /> : "Simpan"}
        </Button>
      </ButtonContainer>
    </ContainerProfile>
  );
}
