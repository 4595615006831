import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import { useAuthenticate, useOtp } from "../network/resolver";
import { LoadingButton } from "../../../components/button/Button";
import { useToast } from "../../../context/toast/ToastContext";

export default function RequestOtp() {
  const toast = useToast();
  const [otp, setOtp] = useState("");
  const { mutate: mutateOtp, isLoading: loadingOtp } = useOtp();
  const { mutate: mutateLogin, isLoading: loadingLogin } = useAuthenticate();
  const loading = loadingOtp || loadingLogin;

  function handleRequestOtp(type: "email" | "phone") {
    mutateOtp();
  }

  function handleLogin() {
    if (!otp) {
      toast.show("Mohon mengisi kode OTP");
      return;
    }
    mutateLogin({ otp });
  }

  return (
    <Box marginBottom={2}>
      <TextField
        label="Kode Otp"
        placeholder="Kode Otp"
        onChange={(e) => setOtp(e.target.value)}
        margin="normal"
        fullWidth
      />
      <Box marginTop={1} display={"flex"} gap={2}>
        <Button
          variant="contained"
          fullWidth
          color="secondary"
          onClick={() => handleRequestOtp("email")}
        >
          Request By Email
        </Button>
        <Button
          variant="contained"
          fullWidth
          color="secondary"
          onClick={() => handleRequestOtp("phone")}
        >
          Request By Phone
        </Button>
      </Box>
      <Box marginTop={2} display={"flex"} justifyContent={"flex-end"}>
        <LoadingButton text="Login" onClick={handleLogin} isLoading={loading} />
      </Box>
    </Box>
  );
}
