import { Box } from "@mui/system";
import TableFC from "../../../../components/table/Table";
import { useGetHistoryPurchase } from "../../network/resolver";
import { useState } from "react";
import TableHeader from "../../../../components/table/TableHeader";

interface HistoryPurhcaseProps {
  idUser: number;
}

export default function HistoryPurchase({ idUser }: HistoryPurhcaseProps) {
  const [page, setPage] = useState(1);
  const { data: dataRawPurchaseHistory } = useGetHistoryPurchase({
    idUser,
    page,
    enabled: idUser !== undefined,
  });
  const dataPurchaseHistory = dataRawPurchaseHistory?.data.data;
  const dataPurchaseHistoryRows = dataRawPurchaseHistory?.data.totalRows;
  const bodyTable = [
    { key: "name" },
    { key: "price", isMoney: true },
    { key: "createdAt", isDate: true },
    { key: "img", isImage: true },
  ];

  return (
    <Box>
      <TableHeader title="List Penukaran Hadiah" />
      <TableFC
        showIndex
        totalRows={dataPurchaseHistoryRows}
        data={dataPurchaseHistory}
        body={bodyTable}
        head={["No", "Nama", "Harga", "Tanggal", "Gambar"]}
        perColumns={5}
        onPageChange={(e) => setPage(e)}
      />
    </Box>
  );
}
