import {
  Box,
  Button,
  Card,
  CircularProgress,
  Paper,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useToast } from "../../context/toast/ToastContext";
import { usePostForgotPassword, usePreLogin } from "./network/resolver";
import RequestOtp from "./components/RequestOtp";
import PreLogin from "./components/PreLogin";

export default function Login() {
  const toast = useToast();
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [showForgot, setShowForgot] = useState(false);
  const { mutate: mutateForgot, isLoading: loadingForgot } =
    usePostForgotPassword();
  const [showOtp, setShowOtp] = useState(false);

  function handleForgotPassword() {
    if (!email) {
      toast.show("Mohon lengkapi field");
      return;
    }
    mutateForgot({
      email,
    });
  }

  return (
    <Box
      padding={2}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
    >
      <Card>
        <Box
          padding={2}
          width={500}
          component={Paper}
          display="flex"
          flexDirection="column"
          margin="dense"
        >
          {showForgot ? (
            <TextField
              label="Email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
            />
          ) : (
            <PreLogin />
          )}
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            marginTop={1}
            flexDirection="row"
            gap={1}
          >
            <Button onClick={() => setShowForgot(true)}>Lupa Password</Button>
            {showForgot && (
              <Button variant="contained" onClick={handleForgotPassword}>
                {loadingForgot ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  "Reset"
                )}
              </Button>
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
