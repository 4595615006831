import { HttpBaseResponse } from "../../../core/HttpResponse";
import Http from "../../../core/http-axios-react/http/Http";
import { ICustomerService } from "../CustomerService.type";

export const getCustomerService = async () => {
  const result = await Http.get<HttpBaseResponse<ICustomerService[]>>({
    url: "/customer-service",
  });
  return result;
};

export const postCustomerService = async ({
  liveChat,
  waChat,
}: {
  liveChat?: string;
  waChat?: string;
}) => {
  const result = await Http.post({
    url: "/customer-service",
    data: {
      liveChat,
      waChat,
    },
  });
  return result;
};

export const patchCustomerService = async ({
  liveChat,
  waChat,
}: {
  liveChat?: string;
  waChat?: string;
}) => {
  const result = await Http.put({
    url: "/customer-service",
    data: {
      liveChat,
      waChat,
    },
  });
  return result;
};
