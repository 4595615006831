import { HttpBaseResponse } from "../../../core/HttpResponse";
import Http from "../../../core/http-axios-react/http/Http";
import { LoginAuthProps } from "../Login.type";

interface postRegisterProps {
  name: string;
  username: string;
  password: string;
  email: string;
}

interface IPreLogin {
  token: string;
}

export const postLogin = async ({ otp }: { otp: string }) => {
  const result = await Http.post<HttpBaseResponse<LoginAuthProps>>({
    url: "/auth",
    data: {
      otp,
    },
  });
  return result;
};

export const postPreLogin = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  const result = await Http.post<HttpBaseResponse<IPreLogin>>({
    url: "/auth/prelogin",
    data: {
      username,
      password,
    },
  });
  return result;
};

export const postRegister = async ({
  name,
  username,
  password,
  email,
}: postRegisterProps) => {
  const result = await Http.post<HttpBaseResponse<LoginAuthProps>>({
    url: "/users",
    data: {
      name,
      username,
      password,
      email,
    },
  });
  return result;
};

export const postOtpRequest = async () => {
  const result = await Http.post({
    url: "/auth/otp",
  });
  return result;
};

export const postForgotPassword = async ({ email }: { email: string }) => {
  const result = await Http.post({
    url: "/auth/forgot",
    data: {
      email,
    },
  });
  return result;
};
