import { HttpBaseRequest } from "../../../core/HttpRequest";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import Http from "../../../core/http-axios-react/http/Http";
import { TierTypeRequest, TierTypeResponse } from "../Tier.type";

export const getTier = async ({
  idMemberTier,
}: HttpBaseRequest<{ idMemberTier?: number }>) => {
  const params = {};
  const all = idMemberTier === undefined;
  if (all) {
    Object.assign(params, { all });
  }
  const results = await Http.get<HttpBaseResponse<TierTypeResponse[]>>({
    url: "member/tier",
    params,
  });
  return results;
};

export const postTier = async ({
  minimumSpent,
  name,
  maxPrizeConvert,
  pointConvertRate,
  ticketConvertRate,
  probability,
}: HttpBaseRequest<TierTypeRequest>) => {
  const results = await Http.post<HttpBaseResponse<any>>({
    url: "member/tier",
    data: {
      minimumSpent,
      name,
      maxPrizeConvert,
      pointConvertRate,
      ticketConvertRate,
      probability,
    },
  });
  return results;
};

export const patchTier = async ({
  minimumSpent,
  name,
  maxPrizeConvert,
  pointConvertRate,
  ticketConvertRate,
  probability,
  id,
}: HttpBaseRequest<TierTypeRequest>) => {
  const results = await Http.put<HttpBaseResponse<any>>({
    url: "member/tier",
    data: {
      minimumSpent,
      name,
      maxPrizeConvert,
      pointConvertRate,
      ticketConvertRate,
      probability,
      id,
    },
  });
  return results;
};
