import {
  Box,
  Collapse,
  Drawer,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { BodyContentDrawer, SideBar } from "./Layout.styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import {
  Person,
  Layers,
  SportsEsportsOutlined,
  Inventory,
  ExpandLess,
  ExpandMore,
  LogoutOutlined,
} from "@mui/icons-material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Outlet, useNavigate } from "react-router";
import BackupIcon from '@mui/icons-material/Backup';
import Guard from "../../router/Guard";

export default function Layout() {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [navigationPath, setNavigationPath] = useState([
    {
      text: "User",
      path: "/user",
      icon: <Person style={{ color: "#aeaeae" }} />,
      expand: false,
      ext: [
        {
          text: "List Users",
          path: "/user",
          icon: <Person style={{ color: "#aeaeae" }} />,
        },
        {
          text: "Tambah User",
          path: "/user/admin",
          icon: <Person style={{ color: "#aeaeae" }} />,
        },
        {
          text: "Import TO",
          path: "/user/importto",
          icon: <Person style={{ color: "#aeaeae" }} />,
        },
      ],
    },
    {
      text: "Setting Tier",
      path: "/tier",
      icon: <Layers style={{ color: "#aeaeae" }} />,
    },
    {
      text: "Setting Permainan",
      path: "/mystery-box",
      icon: <SportsEsportsOutlined style={{ color: "#aeaeae" }} />,
      ext: [
        {
          text: "Mystery Box",
          path: "/mystery-box",
          icon: <SportsEsportsOutlined style={{ color: "#aeaeae" }} />,
        },
      ],
    },
    {
      text: "Hadiah",
      path: "/hadiah",
      icon: <CardGiftcardIcon style={{ color: "#aeaeae" }} />,
      ext: [
        {
          text: "List Hadiah",
          path: "/hadiah",
          icon: <CardGiftcardIcon style={{ color: "#aeaeae" }} />,
        },
        {
          text: "Proses Hadiah User",
          path: "/hadiah/proses",
          icon: <CardGiftcardIcon style={{ color: "#aeaeae" }} />,
        },
      ],
    },
    {
      text: "Setting Tampilan",
      path: "/design",
      icon: <Inventory style={{ color: "#aeaeae" }} />,
      ext: [
        {
          text: "Mystery Box",
          path: "/design",
          icon: <Inventory style={{ color: "#aeaeae" }} />,
        },
      ],
    },
    {
      text: "Customer Service",
      path: "/customer-service",
      icon: <SupportAgentIcon style={{ color: "#aeaeae" }} />,
    },
    {
      text: "Backup Database",
      path: "/backup-database",
      icon: <BackupIcon style={{ color: "#aeaeae" }} />,
    },
  ]);

  function handleOnClick(path: string, ext?: any) {
    if (ext) {
      setNavigationPath((prev) =>
        prev.map((item) => {
          if (item.path === path) {
            item.expand = !item.expand;
          }
          return item;
        })
      );
      return;
    }
    navigate(path);
    setOpenDrawer(false);
  }

  function handleLogout() {
    localStorage.clear();
    window.location.reload();
  }

  const ListItemMenu = () => {
    return (
      <List component="nav">
        {navigationPath.map((item, index) => {
          return (
            <div key={index}>
              <ListItemButton
                style={{ color: "#aeaeae" }}
                onClick={() => handleOnClick(item.path, item.ext)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
                <div>
                  {item.ext ? (
                    item.expand ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : null}
                </div>
              </ListItemButton>
              {item.ext && item.expand && (
                <Collapse in={true} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.ext.map((itemExt, indexExt) => {
                      return (
                        <ListItemButton
                          sx={{ pl: 4 }}
                          key={indexExt + index}
                          onClick={() => handleOnClick(itemExt.path)}
                        >
                          <ListItemIcon>{itemExt.icon}</ListItemIcon>
                          <ListItemText
                            style={{ color: "#aeaeae" }}
                            primary={itemExt.text}
                          />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </div>
          );
        })}
        <ListItemButton style={{ color: "#aeaeae" }} onClick={handleLogout}>
          <ListItemIcon>
            <LogoutOutlined style={{ color: "#aeaeae" }} />,
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    );
  };

  return (
    <Guard>
      <Grid container sx={{ backgroundColor: "#ededed" }} minHeight={"100vh"}>
        <SideBar item xs={0} md={3} lg={2} xl={2}>
          <ListItemMenu />
        </SideBar>
        <Grid item xs={12} md={9} lg={10} xl={10}>
          <Box
            bgcolor="white"
            padding={2}
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <BodyContentDrawer>
              <Box
                onClick={() => setOpenDrawer(true)}
                style={{ cursor: "pointer" }}
              >
                <MenuIcon />
              </Box>
            </BodyContentDrawer>
            <Box>
              <Typography variant="h5" fontWeight="bold">
                Welcome, Admin
              </Typography>
            </Box>
            <Box onClick={() => handleOnClick("/user/profile")}>
              <AccountCircleIcon
                style={{ cursor: "pointer" }}
                fontSize={"large"}
              />
            </Box>
          </Box>
          <Outlet />
        </Grid>
        <Drawer
          anchor={"left"}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <Box width={280} bgcolor={"black"} height={"100%"}>
            <ListItemMenu />
          </Box>
        </Drawer>
      </Grid>
    </Guard>
  );
}
