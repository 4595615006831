import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import {
  ButtonContainer,
  MainContainerModal,
  MainContainerWrapper,
} from "../../../styles/Main.styles";
import { HISTORY_STATUS } from "../../../Constant";
import { GetHistoryPurchase } from "../../user/type/user";
import { useEffect, useState } from "react";
import { usePatchHistoryPurchase } from "../../user/network/resolver";

interface ModalUpdateStatusProps {
  show: boolean;
  selectedStatus?: GetHistoryPurchase | undefined;
  onClose(): void;
}

export default function ModalUpdateStatus({
  show,
  selectedStatus,
  onClose,
}: ModalUpdateStatusProps) {
  const [status, setStatus] = useState<number | undefined>(undefined);
  const { mutate: mutatePurchase, isLoading: isLoadingPurchase } =
    usePatchHistoryPurchase();

  useEffect(() => {
    if (selectedStatus) {
      setStatus(selectedStatus.status);
    }
  }, [selectedStatus]);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  function handleSubmit() {
    if (status != undefined && selectedStatus) {
      mutatePurchase({
        id: selectedStatus.id,
        status,
      });
    }
  }

  if (status === undefined) {
    return null
  }

  return (
    <Modal open={show} onClose={handleClose}>
      <MainContainerModal style={{ minWidth: 400 }}>
        <MainContainerWrapper>
          <Box marginBottom={4}>
            <Typography variant="body1" fontWeight={"bold"}>
              Update Status Penukaran Hadiah
            </Typography>
          </Box>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label">Status Hadiah</InputLabel>
            <Select
              value={status}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              label="Status Hadiah"
              onChange={(e) => setStatus(e.target.value as number)}
            >
              <MenuItem value={HISTORY_STATUS.rejected}>Ditolak</MenuItem>
              <MenuItem value={HISTORY_STATUS.waiting}>Menungu Konfirmasi</MenuItem>
              <MenuItem value={HISTORY_STATUS.process}>Diproses</MenuItem>
              <MenuItem value={HISTORY_STATUS.sending}>Dikirim</MenuItem>
              <MenuItem value={HISTORY_STATUS.completed}>Selesai</MenuItem>
            </Select>
          </FormControl>
          <ButtonContainer style={{ marginTop: 12 }}>
            <Button variant="contained" onClick={handleSubmit}>
              {isLoadingPurchase ? <CircularProgress/> : 'Update'}
            </Button>
          </ButtonContainer>
        </MainContainerWrapper>
      </MainContainerModal>
    </Modal>
  );
}
