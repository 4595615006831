import { Button, CircularProgress } from "@mui/material";

export const LoadingButton = ({
  text,
  isLoading,
  onClick,
}: {
  text: string;
  isLoading?: boolean;
  onClick(): void;
}) => {
  return (
    <Button onClick={onClick} variant="contained" disabled={isLoading}>
      {isLoading ? (
        <CircularProgress size={20} />
      ) : (
        text
      )}
    </Button>
  );
};
