import { Box, Card, Container, Tab, Tabs, Typography } from "@mui/material";
import { MainPadding } from "../../../styles/Main.styles";
import { useParams } from "react-router";
import PlayHistory from "../components/HistoryPermainan/PlayHistory";
import HistoryPurchase from "../components/HistoryPurchase/HistoryPurchase";
import { Wrapper } from "../components/modal/UserModal.styles";
import UserProfile from "../components/profile/UserProfile";
import UserTurnOver from "../components/turnOver/UserTurnOver";
import { useState } from "react";
import HistoryLogin from "../components/HistoryLogin/HistoryLogin";
import { USER_TYPE } from "../../../Constant";
import { useGetProfile } from "../network/resolver";

export default function DetailUser() {
  const { id, username } = useParams();
  const [valueTab, setValueTab] = useState(0);
  const idUser = id ? parseInt(id) : -1;
  const { data: dataProfile, isLoading } = useGetProfile({
    id: id ? parseInt(id) : undefined,
  });
  const userType = dataProfile?.data.data.type;

  function handleOnChange(e: number) {
    setValueTab(e);
  }

  const TabForAdmin = () => {
    return (
      <>
        <Tabs
          value={valueTab}
          onChange={(e, v) => handleOnChange(v)}
          style={{ overflowX: "scroll" }}
        >
          <Tab label="History Login" />
          <Tab label="Profile" />
        </Tabs>
        <Box
          width={"100%"}
          height={10}
          bgcolor={"#dedede"}
          marginTop={2}
          borderRadius={5}
        />
        {valueTab === 0 && <HistoryLogin idUser={idUser} />}
        {valueTab === 1 && <UserProfile idUser={idUser} />}
      </>
    );
  };

  const TabForUser = () => {
    return (
      <>
        <Tabs
          value={valueTab}
          onChange={(e, v) => handleOnChange(v)}
          style={{ overflowX: "scroll" }}
        >
          <Tab label="Turn Over" />
          <Tab label="History Permainan" />
          <Tab label="History Penukaran Hadiah" />
          <Tab label="History Login" />
          <Tab label="Profile" />
        </Tabs>
        <Box
          width={"100%"}
          height={10}
          bgcolor={"#dedede"}
          marginTop={2}
          borderRadius={5}
        />
        {valueTab === 0 && (
          <UserTurnOver idUser={idUser} username={username || ""} />
        )}
        {valueTab === 1 && <PlayHistory idUser={idUser} />}
        {valueTab === 2 && <HistoryPurchase idUser={idUser} />}
        {valueTab === 3 && <HistoryLogin idUser={idUser} />}
        {valueTab === 4 && <UserProfile idUser={idUser} />}
      </>
    );
  };

  if (idUser === -1) {
    return (
      <MainPadding>
        <Card>
          <Box padding={2}>
            <Typography textAlign={"center"}>
              Tidak dapat menemukan user
            </Typography>
          </Box>
        </Card>
      </MainPadding>
    );
  }

  if (isLoading) {
    return null;
  }

  return (
    <MainPadding>
      <Container>
        <Wrapper>
          {userType === USER_TYPE.admin.code ? <TabForAdmin /> : <TabForUser />}
        </Wrapper>
      </Container>
    </MainPadding>
  );
}
