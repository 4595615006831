import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import {
  postForgotPassword,
  postLogin,
  postOtpRequest,
  postPreLogin,
} from "./service";
import { AxiosError } from "axios";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import { useToast } from "../../../context/toast/ToastContext";
import { ENV_LISTS } from "../../../core/EnvTranspiler";
import { useUserInformation } from "../../../context/userInformation/UserInformation";
import { TokenStorage } from "../../../core/http-axios-react/http/Token";

export const useAuthenticate = () => {
  const toast = useToast();
  const userInformation = useUserInformation()
  const navigate = useNavigate();

  return useMutation({
    mutationKey: "login",
    mutationFn: ({ otp }: { otp: string }) => postLogin({ otp }),
    onSuccess: (e) => {
      TokenStorage.set({
        key: ENV_LISTS.KEY_TOKEN,
        value: e.data.data.token,
      });
      userInformation.refetch()
      navigate("/");
      //window.location.reload();
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};

export const usePreLogin = () => {
  const toast = useToast();
  return useMutation({
    mutationKey: "preLogin",
    mutationFn: ({
      username,
      password,
    }: {
      username: string;
      password: string;
    }) => postPreLogin({ username, password }),
    onSuccess: (e) => {
      TokenStorage.set({
        key: ENV_LISTS.KEY_TOKEN,
        value: e.data.data.token,
      });
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};

export const useOtp = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: () => postOtpRequest(),
    onSuccess: (e) => {
      toast.show("Kode OTP telah dikirim");
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};

export const usePostForgotPassword = () => {
  const toast = useToast();
  return useMutation({
    mutationKey: "forgotPassword",
    mutationFn: ({ email }: { email: string }) => postForgotPassword({ email }),
    onSuccess: (e) => {
      toast.show("Kode OTP telah dikirim");
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};
