import { AxiosError } from "axios";
import { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import {
  ToastStateContext,
  useToast,
} from "../../../context/toast/ToastContext";
import { HttpBaseRequest } from "../../../core/HttpRequest";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import { PrizeRequest } from "../Prize.type";
import { addPrize, deletePrize, getPrize, updatePrize } from "./service";

export const useGetPrize = ({
  id,
  all,
  limit,
  page,
  idMemberTier,
  name,
  enabledQuery = false,
}: HttpBaseRequest<PrizeRequest>) => {
  return useQuery(
    [`prize${idMemberTier}${name}${page}`],
    () => getPrize({ id, all, limit, page, idMemberTier, name }),
    { enabled: enabledQuery }
  );
};

export const useAddPrize = () => {
  const toast = useContext(ToastStateContext);
  return useMutation({
    mutationFn: ({
      price,
      name,
      img,
    }: {
      price: number;
      name: string;
      img: File;
    }) => addPrize({ price, name, img }),
    onSuccess: () => {
      toast.show("Berhasil Menambahkan Hadiah");
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};

export const usePatchPrize = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: ({
      id,
      price,
      name,
      img,
    }: {
      id: number;
      price?: number;
      name?: string;
      img?: File;
    }) => updatePrize({ id, price, name, img }),
    onSuccess: () => {
      toast.show("Berhasil update hadiah");
    },
  });
};

export const useDeletePrize = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: ({ id }: { id: number }) => deletePrize({ id }),
    onSuccess: (e) => {
      toast.show("Berhasil menghapus data");
      window.location.reload();
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};
