import { AxiosError } from "axios";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  ToastStateContext,
  useToast,
} from "../../../context/toast/ToastContext";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import { PatchUserType } from "../type/user";
import {
  addUser,
  getHistoryPlay,
  getHistoryPurchase,
  getTurnOver,
  getUser,
  getUserProfile,
  patchHistoryPurchase,
  patchUser,
  postAddTurnOver,
} from "./service";

export const useUser = ({
  page,
  username,
  type,
  status,
}: {
  page: number;
  username?: string;
  type: number;
  status: number;
}) => {
  return useQuery({
    queryKey: ["user", page, username, type, status],
    queryFn: () => getUser({ page, username, type, status }),
    keepPreviousData: true,
  });
};

export const useAddUser = () => {
  const toast = useContext(ToastStateContext);
  return useMutation({
    mutationKey: "addUser",
    mutationFn: ({
      name,
      username,
      password,
      phone,
      email,
      role,
    }: {
      name: string;
      username: string;
      password: string;
      phone: string;
      email: string;
      role: number;
    }) => addUser({ name, username, password, phone, email, role }),
    onSuccess: () => {
      toast.show("Berhasil menambahkan user");
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};

export const useAddTurnOver = () => {
  const query = useQueryClient();
  const toast = useContext(ToastStateContext);
  return useMutation({
    mutationFn: ({
      username,
      amount,
      files,
    }: {
      username?: string;
      amount?: number;
      files?: any;
    }) => postAddTurnOver({ username, amount, files }),
    onSuccess: () => {
      toast.show("berhasil menambahkan turn over");
      query.invalidateQueries();
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};

export const useGetTurnOver = ({
  idUser,
  page,
}: {
  idUser: number;
  page: number;
}) => {
  return useQuery(
    [`turnOver${idUser}${page}`],
    () => getTurnOver({ idUser, page }),
    {
      keepPreviousData: true,
    }
  );
};

export const useGetProfile = ({ id }: { id?: number }) => {
  return useQuery([`userProfile${id || "own"}`], () => getUserProfile({ id }));
};

export const usePatchUser = ({
  bank_acc_name,
  bank_acc_no,
  email,
  phone,
  username,
}: PatchUserType) => {
  const query = useQueryClient();
  const toast = useContext(ToastStateContext);
  return useMutation(
    ["userPatch"],
    () => patchUser({ bank_acc_name, bank_acc_no, email, phone, username }),
    {
      onSuccess: (e) => {
        query.invalidateQueries();
        toast.show("Berhasil update user");
      },
      onError: (e: AxiosError<HttpBaseResponse<any>>) => {
        const msg =
          e.response?.data.user_error ||
          "Terjadi kesalahan mohon coba beberapa saat lagi";
        toast.show(msg);
      },
    }
  );
};

export const useGetHistoryPlay = ({
  idUser,
  page,
  enabled,
}: {
  idUser: number;
  page: number;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: `historyPlay${idUser}${page}`,
    queryFn: () => getHistoryPlay({ idUser, page }),
    enabled,
  });
};

export const useGetHistoryPurchase = ({
  idUser,
  enabled,
  page,
  name,
  type,
}: {
  idUser?: number;
  enabled: boolean;
  page?: number;
  name?: string;
  type?: number;
}) => {
  return useQuery({
    queryKey: ["historyPurhcase", page, name, type],
    queryFn: () => getHistoryPurchase({ idUser, page, name, type }),
    enabled,
  });
};

export const usePatchHistoryPurchase = () => {
  const toast = useToast();
  const query = useQueryClient();
  return useMutation({
    mutationFn: ({ id, status }: { id: number; status: number }) =>
      patchHistoryPurchase({ id, status }),
    onSuccess: () => {
      query.invalidateQueries();
      toast.show("Berhasil update status");
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};
