import {
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { InputMoney } from "../../../components/input/input";
import { useToast } from "../../../context/toast/ToastContext";
import { MainContainerModal } from "../../../styles/Main.styles";
import { usePatchTier, usePostTier } from "../network/resolver";
import { TierTypeResponse } from "../Tier.type";
import { WrapperTier } from "./TierModal.styles";

interface TierModalProps {
  show: boolean;
  onClose(): void;
  dataTier?: TierTypeResponse | null;
}

export default function TierModal({ show, onClose, dataTier }: TierModalProps) {
  const toast = useToast();
  const { mutate: mutatePostTier, isLoading } = usePostTier();
  const { mutate: mutatePatchTier } = usePatchTier();
  const [name, setName] = useState<string | undefined>("");
  const [minTo, setMinTo] = useState<number | undefined>(0);
  const [pointConvertRate, setPointConvertRate] = useState<number | undefined>(
    0
  );
  const [ticketConvertRate, setTicketConvertRate] = useState<
    number | undefined
  >(0);
  const [maxPrizeConvert, setMaxPrizeConvert] = useState<string>("0.5");
  const [probability, setProbability] = useState(50);

  useEffect(() => {
    setMinTo(dataTier?.minimum_spent);
    setName(dataTier?.name || "");
    setPointConvertRate(dataTier?.point_convert_rate);
    setTicketConvertRate(dataTier?.ticket_convert_rate);
    setMaxPrizeConvert(dataTier?.max_prize_convert.toString() || '0.5')
    setProbability(dataTier?.probability || 50)
  }, [show]);

  function handleSubmit() {
    if (dataTier?.id && minTo && name) {
      mutatePatchTier({
        id: dataTier.id,
        minimumSpent: minTo,
        name,
        maxPrizeConvert: maxPrizeConvert ? parseFloat(maxPrizeConvert) : 0,
        probability,
        pointConvertRate,
        ticketConvertRate,
      });
    } else if (name && minTo) {
      mutatePostTier({
        minimumSpent: minTo,
        name,
        maxPrizeConvert: maxPrizeConvert ? parseFloat(maxPrizeConvert) : 0,
        probability,
        pointConvertRate,
        ticketConvertRate,
      });
    } else {
      toast.show("Mohon melengkapi field");
    }
  }

  function handleClose() {
    onClose();
  }

  return (
    <Modal open={show} onClose={handleClose}>
      <MainContainerModal style={{ minWidth: 400 }}>
        <WrapperTier>
          <Box>
            <Typography variant="body1" fontWeight={"bold"}>
              Level Tier
            </Typography>
          </Box>
          <TextField
            value={name}
            label="Nama"
            margin="normal"
            fullWidth
            onChange={(e) => setName(e.target.value)}
          />
          <Box marginTop={2}>
            <InputMoney
              label={`Minimum Total TO`}
              key="Minimum TO"
              value={minTo}
              onChange={(e) => setMinTo(e)}
            />
          </Box>
          <Box marginTop={2}>
            <InputMoney
              label="Total TO per Point"
              key="Total TO per Point"
              value={pointConvertRate}
              onChange={(e) => setPointConvertRate(e)}
            />
          </Box>
          <Box marginTop={2}>
            <InputMoney
              label="Point per Ticket"
              key="Rate/Ticket"
              value={ticketConvertRate}
              onChange={(e) => setTicketConvertRate(e)}
            />
          </Box>
          <Box marginTop={2}>
            <TextField
              value={probability}
              onChange={(e) => {
                const value = parseInt(e.target.value) || 0;
                setProbability(value);
              }}
              fullWidth
              label="Kemungkinan User Menang (%)"
            />
          </Box>
          <Box marginTop={2}>
            <TextField
              value={maxPrizeConvert}
              onChange={(e) => {
                setMaxPrizeConvert(e.target.value);
              }}
              fullWidth
              label="Maksimal hadiah berdasarkan total TO"
            />
          </Box>
          <Box marginTop={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={handleSubmit}>
              {isLoading ? <CircularProgress /> : "Submit"}
            </Button>
          </Box>
        </WrapperTier>
      </MainContainerModal>
    </Modal>
  );
}
