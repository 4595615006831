import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import { useToast } from "../../../context/toast/ToastContext";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import { DesignRequest } from "../Design.type";
import { getDesign, patchDesign, patchThumbnail } from "./service";

export const useGetDesign = () => {
  return useQuery({
    queryKey: "design",
    queryFn: () => getDesign(),
    enabled: true,
  });
};

export const usePatchDesign = () => {
  const { show } = useToast();
  return useMutation({
    mutationKey: "design-patch",
    mutationFn: ({
      coverType,
      coverSrcBlob,
      logo,
      runningText,
      thumbnails,
      videoUrl,
    }: DesignRequest) =>
      patchDesign({
        coverType,
        coverSrcBlob,
        logo,
        runningText,
        thumbnails,
        videoUrl,
      }),
    onSuccess: (e) => {
      show("Berhasil update design");
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      show(msg);
    },
  });
};

export const usePatchThumbnail = () => {
  const { show } = useToast();
  return useMutation({
    mutationKey: "updateThumbnail",
    mutationFn: ({
      id,
      index,
      img,
    }: {
      id: number;
      index: number;
      img: File;
    }) => patchThumbnail({ id, index, img }),
    onSuccess: () => show("Berhasil update design"),
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      show(msg);
    },
  });
};
