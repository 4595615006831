import axios from "axios";
import { API_TIMEOUT, BASE_URL } from "../constants/Constants";
import { TokenStorage } from "./Token";

interface IRequest {
  url: string;
  data?: any;
  headers?: any;
  params?: any;
  useFormMultiPart?: boolean;
  showToast?: boolean;
  ignoreFalsy?: boolean;
}

/**
 * Create Axios Instance with default
 */
export const apiInstance = axios.create({
  timeout: API_TIMEOUT,
  baseURL: BASE_URL,
});

// request interceptors
apiInstance.interceptors.request.use(async (config) => {
  // custom request interceptors
  const token = TokenStorage.get();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

apiInstance.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err: any) => {
    return Promise.reject(err);
  }
);

const get = <T = any>({ url, params }: IRequest) => {
  let urlTarget = url;
  // Build url to params
  if (params) {
    urlTarget += "?";
    Object.keys(params).forEach((item) => {
      if (params[item]) {
        urlTarget += `${item}=${params[item]}&`;
      }
    });
    // remove last &
    urlTarget = urlTarget.substring(0, urlTarget.length - 1);
  }
  return apiInstance.get<T>(urlTarget);
};

const post = <T = any>({
  url,
  data,
  useFormMultiPart = true,
  ignoreFalsy = false,
}: IRequest) => {
  if (useFormMultiPart) {
    const form = new FormData();
    data &&
      Object.keys(data).forEach((item) => {
        if (!ignoreFalsy) {
          if (data[item] !== undefined && data[item] !== null) {
            form.append(item, data[item]);
          }
        } else {
          form.append(item, data[item]);
        }
      });
    return apiInstance.post<T>(url, form);
  }
  return apiInstance.post<T>(url, data);
};

const put = <T = any>({
  url,
  data,
  useFormMultiPart = true,
  ignoreFalsy = false,
}: IRequest) => {
  if (useFormMultiPart) {
    const form = new FormData();
    data &&
      Object.keys(data).forEach((item) => {
        if (!ignoreFalsy) {
          if (data[item] !== undefined && data[item] !== null) {
            if (Array.isArray(data[item])) {
              data[item].forEach((itemForm: any, indexForm: number) => {
                form.append(`${item}[${indexForm}]`, itemForm);
              });
            } else {
              form.append(item, data[item]);
            }
          }
        } else {
          form.append(item, data[item]);
        }
      });
    return apiInstance.put<T>(url, form);
  }
  return apiInstance.put<T>(url, data);
};

const deleteReq = <T = any>({
  url,
  data,
  useFormMultiPart = true,
  ignoreFalsy = false,
}: IRequest) => {
  if (useFormMultiPart) {
    const form = new FormData();
    data &&
      Object.keys(data).forEach((item) => {
        if (!ignoreFalsy) {
          if (data[item] !== undefined && data[item] !== null) {
            if (Array.isArray(data[item])) {
              data[item].forEach((itemForm: any, indexForm: number) => {
                form.append(`${item}[${indexForm}]`, itemForm);
              });
            } else {
              form.append(item, data[item]);
            }
          }
        } else {
          form.append(item, data[item]);
        }
      });
    return apiInstance.delete<T>(url, form as any);
  }
  return apiInstance.delete<T>(url, data);
};

const Http = {
  get,
  post,
  put,
  delete: deleteReq,
};

export default Http;
