import {
  FormGroup,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import UploadImage from "../../../../components/input/UploadImage";

interface MiddleCoverProps {
  type: number;
  src: string;
  onUpload(e: File): void;
  onChange(e: string): void;
  onMediaChange(e: number): void;
}

export default function MiddleCover({
  type,
  src,
  onUpload,
  onChange,
  onMediaChange,
}: MiddleCoverProps) {
  const [isVideo, setIsVideo] = useState(type === 1);
  const [videoPath, setVideoPath] = useState(type === 0 ? src : "");

  function handleOnUpload(file: FileList) {
    onUpload(file[0]);
  }

  function handleOnChange(e: string) {
    onChange(e);
  }

  function handleOnMediaChange(e: boolean) {
    setIsVideo(e);
    //onMediaChange(e ? 0 : 1);
  }

  return (
    <div>
      <Typography variant="h6">Cover</Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              value={isVideo}
              onChange={(e, v) => handleOnMediaChange(v)}
            />
          }
          label="Video"
        />
      </FormGroup>
      {isVideo ? (
        <TextField
          onChange={(e) => handleOnChange(e.target.value)}
          value={videoPath}
          label="Url"
          placeholder="Url"
          margin="dense"
          fullWidth
        />
      ) : (
        <div>
          <UploadImage
            id="middle-cover"
            defaultView={src}
            onUpload={handleOnUpload}
          />
        </div>
      )}
    </div>
  );
}
