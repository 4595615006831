import {
  Box,
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { InputMoney } from "../../../components/input/input";
import UploadImage from "../../../components/input/UploadImage";
import { useToast } from "../../../context/toast/ToastContext";
import {
  MainContainerModal,
  MainContainerWrapper,
} from "../../../styles/Main.styles";
import {
  useAddPrize,
  useDeletePrize,
  usePatchPrize,
} from "../network/resolver";
import { PrizeResponse } from "../Prize.type";

interface ModalAddPrizeProps {
  visible: boolean;
  onClose(): void;
  prize?: PrizeResponse | null;
}

export default function ModalAddPrize({
  visible,
  onClose,
  prize,
}: ModalAddPrizeProps) {
  const toast = useToast();
  const { mutate: mutatePrize } = useAddPrize();
  const { mutate: mutatePatchPrize } = usePatchPrize();
  const { mutate: mutateDeletePrize } = useDeletePrize();
  const [name, setName] = useState<string>();
  const [price, setPrice] = useState(0);
  const [img, setImg] = useState<FileList>();
  const [isLoading, setIsLoading] = useState(false);
  const [forceImageToUpdate, setForceImageToUpdate] = useState(false);

  useEffect(() => {
    setName(prize?.name || "");
    setPrice(prize?.price || 0);
  }, [prize]);

  function handleSubmit() {
    if (!forceImageToUpdate) {
      toast.show("Field gambar harus di isi atau diperbaharui");
      return;
    } else if (!name || !price || !img) {
      toast.show("Mohon lengkapi field");
      return;
    }
    setIsLoading(true);
    if (prize?.id) {
      mutatePatchPrize({
        id: prize.id,
        img: img[0],
        price,
        name,
      });
    } else if (name && price && img) {
      mutatePrize({
        name,
        img: img[0],
        price,
      });
    }
    setIsLoading(false);
  }

  function handleDelete() {
    if (prize) {
      if (confirm("Apakah anda yakin ?")) {
        mutateDeletePrize({ id: prize.id });
      }
    }
  }

  return (
    <Modal open={visible} onClose={onClose}>
      <MainContainerModal style={{ minWidth: 400 }}>
        <MainContainerWrapper>
          <Box marginBottom={1}>
            <Typography variant="body1" fontWeight={"bold"}>
              {prize ? "Update" : "Tambah"} Hadiah Penukaran
            </Typography>
          </Box>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nama"
            label="Nama"
            fullWidth
            margin="normal"
          />
          <Box marginTop={1}>
            <InputMoney
              onChange={(e) => setPrice(e)}
              value={price}
              label="Harga"
            />
          </Box>
          <UploadImage
            id="modalAddPrize"
            defaultView={prize?.img}
            onUpload={(e) => {
              setForceImageToUpdate(true);
              setImg(e);
            }}
          />
          <Box display="flex" justifyContent="flex-end" gap={1}>
            {prize && (
              <Button variant="contained" color="error" onClick={handleDelete}>
                Delete
              </Button>
            )}
            <Button variant="contained" onClick={handleSubmit}>
              {isLoading ? <CircularProgress /> : "Submit"}
            </Button>
          </Box>
        </MainContainerWrapper>
      </MainContainerModal>
    </Modal>
  );
}
