import { createBrowserRouter } from "react-router-dom";
import Design from "../features/design";
import CustomerService from "../features/customer_service/CustomerService";
import Prize from "../features/hadiah";
import ProsesHadiah from "../features/hadiah/ProsesHadiah";
import MysteryBox from "../features/mystery_box";
import Profile from "../features/profile/Profile";
import Tier from "../features/tier/Tier";
import ImportTO from "../features/user/ImportTO";
import User from "../features/user/User";
import UserAdmin from "../features/user/UserAdmin";
import DetailUser from "../features/user/pages/DetailUser";
import Login from "../features/login/Login";
import Layout from "../features/layout/Layout";
import BackupDatabase from "../features/backup_database/BackupDatabase";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Layout/>,
    children: [
      {
        index: true,
        element: <User />,
      },
      {
        path: "/user",
        element: <User />,
      },
      {
        path: "/user/profile",
        element: <Profile />,
      },
      {
        path: "/user/admin",
        element: <UserAdmin />,
      },
      {
        path: "/user/importto",
        element: <ImportTO />,
      },
      {
        path: "/user/:id/:username",
        element: <DetailUser />,
      },
      {
        path: "/tier",
        element: <Tier />,
      },
      {
        path: "/hadiah",
        element: <Prize />,
      },
      {
        path: "/hadiah/proses",
        element: <ProsesHadiah />,
      },
      {
        path: "/design",
        element: <Design />,
      },
      {
        path: "/mystery-box",
        element: <MysteryBox />,
      },
      {
        path: "/customer-service",
        element: <CustomerService />,
      },
      {
        path: "/backup-database",
        element: <BackupDatabase />,
      },
    ],
  },
]);

export default router;
