import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import UploadImage from "../../../components/input/UploadImage";
import { ButtonContainer, MainPadding } from "../../../styles/Main.styles";
import MiddleCover from "../components/MiddleCover/MiddleCover";
import { DesignRequest } from "../Design.type";
import {
  useGetDesign,
  usePatchDesign,
  usePatchThumbnail,
} from "../network/resolver";
import { LogoContainer } from "./Design.styles";

interface ThumbnailProps {
  index: number;
  file: FileList;
  src: string;
}

export default function Design() {
  const { data: dataDesignRaw } = useGetDesign();
  const { mutate: mutateThumbnail } = usePatchThumbnail();
  const dataDesign = dataDesignRaw?.data.data;
  const { mutate: mutateDesign } = usePatchDesign();
  const [imageThubmnail, setImageThumbnail] = useState<ThumbnailProps[]>([
    {} as ThumbnailProps,
    {} as ThumbnailProps,
    {} as ThumbnailProps,
  ]);
  const [logo, setLogo] = useState<File>();
  const [videoPath, setVideoPath] = useState("");
  const [coverImage, setCoverImage] = useState<File>();
  const [runningText, setRunningText] = useState("");
  const [mediaType, setMediaType] = useState(0);

  useEffect(() => {
    if (dataDesign) {
      const temp = [...imageThubmnail];
      setRunningText(dataDesign.setting.runningText);
      setMediaType(dataDesign.setting.media.cover.type);
      dataDesign.setting.media.thumbnail.forEach((item, index) => {
        temp[index].src = item;
      });
      setImageThumbnail(temp);
    }
  }, [dataDesign]);

  function handleOnUpload(file: FileList) {
    setLogo(file[0]);
  }

  function handleOnUploadThumbnail(file: FileList, index: number) {
    const temp = [...imageThubmnail];
    temp[index].file = file;
    temp[index].index = index;
    setImageThumbnail(temp);
    if (dataDesign) {
      mutateThumbnail({ id: dataDesign.id, index: index, img: file[0] });
    }
  }

  function handleSubmit() {
    const data: DesignRequest = {
      logo: logo,
      runningText: runningText,
      coverType: 0,
      videoUrl: videoPath,
      coverSrcBlob: coverImage,
    };
    mutateDesign(data);
  }

  if (!dataDesign) {
    return null;
  }

  return (
    <MainPadding>
      <Card sx={{ marginBottom: 2 }}>
        <CardContent>
          <Typography variant="h6">Logo</Typography>
          <LogoContainer>
            <UploadImage
              defaultView={dataDesign.setting?.logo}
              id="design-1"
              onUpload={(e) => handleOnUpload(e)}
            />
          </LogoContainer>
        </CardContent>
      </Card>
      <Card sx={{ marginBottom: 2 }}>
        <CardContent>
          <MiddleCover
            onMediaChange={(e) => setMediaType(e)}
            onUpload={(e) => setCoverImage(e)}
            onChange={(e) => setVideoPath(e)}
            type={mediaType}
            src={dataDesign.setting.media.cover.src}
          />
        </CardContent>
      </Card>
      <Card sx={{ marginBottom: 2 }}>
        <CardContent>
          <Typography variant="h6">Thumbnail</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <UploadImage
                id="design-2"
                defaultView={imageThubmnail[0].src}
                onUpload={(e) => handleOnUploadThumbnail(e, 0)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <UploadImage
                id="design-3"
                defaultView={imageThubmnail[1].src}
                onUpload={(e) => handleOnUploadThumbnail(e, 1)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <UploadImage
                id="design-4"
                defaultView={imageThubmnail[2].src}
                onUpload={(e) => handleOnUploadThumbnail(e, 2)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ marginBottom: 2 }}>
        <CardContent>
          <TextField
            onChange={(e) => setRunningText(e.target.value)}
            value={runningText}
            label="Text Berjalan"
            placeholder="Text Berjalan"
            margin="dense"
            fullWidth
          />
        </CardContent>
      </Card>
      <ButtonContainer>
        <Button onClick={handleSubmit} variant="contained">
          Submit
        </Button>
      </ButtonContainer>
    </MainPadding>
  );
}
