import { UploadImageContainer } from "./Input.styles";
import { useEffect, useState } from "react";
import ImageCover from "../image/Image";

interface UploadImageProps {
  id: string;
  onUpload(file: FileList): void;
  defaultView?: string;
}

export default function UploadImage({
  onUpload,
  defaultView,
  id,
}: UploadImageProps) {
  const [uploadPath, setUploadPath] = useState<string | null>(null);
  const [shouldOverride, setShouldOverride] = useState(false);

  useEffect(() => {
    if (defaultView) {
      setUploadPath(defaultView);
    }
  }, [defaultView]);

  async function handleChange(e: FileList | null) {
    if (e) {
      onUpload(e);
      const objectUrl = URL.createObjectURL(e[0]);
      setShouldOverride(true)
      setUploadPath(objectUrl);
    }
  }

  return (
    <div>
      <input
        accept="image/png, image/gif, image/jpeg"
        style={{ display: "none" }}
        id={`upload-image-${id}`}
        type="file"
        onChange={(e) => handleChange(e.target.files)}
      />
      <label htmlFor={`upload-image-${id}`}>
        <UploadImageContainer>
          {uploadPath ? (
            <div style={{height: 150}}>
              <ImageCover overideDefault={shouldOverride} src={uploadPath} />
            </div>
          ) : (
            <div>
              <div>Pilih Gambar</div>
            </div>
          )}
        </UploadImageContainer>
      </label>
    </div>
  );
}
