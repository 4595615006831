import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { MainPadding } from "../../styles/Main.styles";
import { useAddTurnOver } from "./network/resolver";
import { useState } from "react";

export default function ImportTO() {
  const [file, setFile] = useState<FileList>();
  const { mutateAsync: mutateTurnOver, isLoading: loadingMutate } =
    useAddTurnOver();
  const [errorMsg, setErrorMsg] = useState("");

  function handleUpload(e: any) {
    setFile(e);
  }

  function handleSubmit() {
    if (file) {
      mutateTurnOver({ files: file[0] })
        .then(() => {})
        .catch((e) => {
          setErrorMsg(e.response.data.user_error);
        });
    }
  }

  return (
    <MainPadding>
      <Box bgcolor={"#fefefe"} padding={1} borderRadius={1}>
        <Typography variant="body1" textAlign={"center"}>
          Pilih file dengan format <b>.csv</b> atau <b>.xlsx</b> untuk melakukan
          import
        </Typography>
        <Box
          marginTop={2}
          marginBottom={2}
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Button variant="outlined" component="label">
            {file ? file[0].name : "Upload File"}
            <input
              onChange={(e) => handleUpload(e.target.files)}
              type="file"
              hidden
              accept=".csv,.xlsx"
            />
          </Button>
          {file && (
            <Box marginTop={2}>
              <Button onClick={handleSubmit} variant="contained">
                {loadingMutate ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          )}
          {errorMsg && (
            <Box marginTop={3} textAlign={"center"}>
              <Box>
                <Typography variant="caption">
                  Tidak dapat melakukan proses import TO, dikarenakan
                  permasalahan sebagai berikut:
                </Typography>
              </Box>
              <Typography variant="caption" color={"red"} fontWeight={"bold"}>
                {errorMsg}
              </Typography>
            </Box>
          )}
          <Box marginTop={2}>
            <a href="/input.csv" download={'input.csv'} >Download Contoh File</a>
          </Box>
        </Box>
      </Box>
    </MainPadding>
  );
}
