import { Box } from "@mui/material";
import React, { useState } from "react";
import { useGetTurnOver } from "../../network/resolver";
import UserModalTurnOver from "../modalTurnOver/UserModalTurnOver";
import TableFC from "../../../../components/table/Table";
import TableHeader from "../../../../components/table/TableHeader";

interface UserTurnOverProps {
  idUser: number;
  username: string;
}

export default function UserTurnOver({ idUser, username }: UserTurnOverProps) {
  const [showTurnOverModal, setShowTurnOverModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const { data: turnOverData } = useGetTurnOver({
    idUser,
    page: pageNumber,
  });
  const bodyTable = [
    { key: "amount", isMoney: true },
    { key: "rate_point", isMoney: true },
    { key: "gained_point" },
    { key: "rate_ticket" },
    { key: "gained_ticket" },
    { key: "created_at", isDate: true },
  ];

  function handleAdd() {
    setShowTurnOverModal(true);
  }

  return (
    <React.Fragment>
      <Box>
        <TableHeader title="List TO User" addButton onClickAdd={handleAdd} />
        <TableFC
          showIndex
          totalRows={turnOverData?.data.totalRows}
          data={turnOverData?.data.data}
          body={bodyTable}
          head={[
            "No",
            "Jumlah",
            "TO/Point",
            "Point Didapat",
            "Point/Tiket",
            "Tiket Didapat",
            "Tanggal",
          ]}
          onPageChange={(e) => setPageNumber(e)}
        />
      </Box>
      <UserModalTurnOver
        username={username}
        showModal={showTurnOverModal}
        closeModal={() => setShowTurnOverModal(false)}
      />
    </React.Fragment>
  );
}
