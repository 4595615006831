import {
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Pagination,
  Box,
  Card,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { ContainerPaggination } from "../../features/user/style/User.styles";
import { FromStringToDate, ToMoneyFormat } from "../../utils/Helper";
import ImageCover from "../image/Image";
import { SearchOff } from "@mui/icons-material";

interface TableFCBodyProps {
  key: string;
  isImage?: boolean;
  isMoney?: boolean;
  isDate?: boolean;
}

interface TableFCProps {
  head: string[];
  body: TableFCBodyProps[];
  data: any[] | undefined | null;
  showIndex?: boolean;
  totalRows?: number;
  perColumns?: number;
  children?: JSX.Element | JSX.Element[];
  onPageChange?(page: number): void;
  onCellClick?(item: any): void;
}

const TableFC = ({
  body,
  head,
  data,
  showIndex,
  totalRows,
  perColumns = 10,
  children,
  onPageChange,
  onCellClick,
}: TableFCProps) => {
  const [pagginationLength, setPagginationLength] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (totalRows) {
      const calculateRows =
        totalRows > 0 ? Math.ceil(totalRows / perColumns) : 0;
      setPagginationLength(calculateRows);
    }
  }, [totalRows]);

  function handleClickCell(item: any) {
    if (onCellClick) {
      onCellClick(item);
    }
  }

  function handleClickPaggination(index: number) {
    setPage(index);
    if (onPageChange) {
      onPageChange(index);
    }
  }

  function getIndex(index: number) {
    if (page === 1) {
      return index;
    }
    return index + (page - 1) * perColumns;
  }

  function renderText(condition: TableFCBodyProps, text: string) {
    if (condition.isDate) {
      return FromStringToDate(text);
    } else if (condition.isMoney) {
      return ToMoneyFormat(text);
    }
    return text;
  }

  if (data && data.length === 0) {
    return (
      <Card>
        <Box
          display={"flex"}
          justifyContent={"center"}
          padding={2}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <SearchOff fontSize={"large"} />
          <Box marginTop={2}>
            <Typography variant="body1">
              Tidak dapat menemukan data yang dicari
            </Typography>
          </Box>
        </Box>
      </Card>
    );
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {head.map((item, index) => {
                return <TableCell key={index}>{item}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((item, index) => {
                return (
                  <TableRow key={index} style={{ cursor: "pointer" }}>
                    {showIndex && (
                      <TableCell onClick={() => handleClickCell(item)}>
                        {getIndex(index + 1)}
                      </TableCell>
                    )}
                    {body.map((itemChild, indexChild) => {
                      return (
                        <Fragment key={indexChild}>
                          {itemChild.isImage ? (
                            <TableCell onClick={() => handleClickCell(item)}>
                              <div style={{ width: 150, height: 150 }}>
                                <ImageCover src={item[itemChild.key]} />
                              </div>
                            </TableCell>
                          ) : (
                            <TableCell onClick={() => handleClickCell(item)}>
                              {renderText(itemChild, item[itemChild.key])}
                            </TableCell>
                          )}
                        </Fragment>
                      );
                    })}
                    {children && <TableCell>{children}</TableCell>}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {pagginationLength > 0 && (
        <ContainerPaggination>
          <Pagination
            onChange={(e, index) => handleClickPaggination(index)}
            count={pagginationLength}
            variant="outlined"
            shape="rounded"
          />
        </ContainerPaggination>
      )}
    </div>
  );
};

export default TableFC;
