import { Box, Button, Typography } from "@mui/material";
import { useGetProfile } from "../../user/network/resolver";
import { MaskingText } from "../../../utils/Helper";
import Keamanan from "./Keamanan";
import { useState } from "react";

export default function ProfileInformation() {
  const { data: dataRaw } = useGetProfile({});
  const dataUser = dataRaw?.data.data;
  const [type, setType] = useState<"email" | "password">("password");
  const [visible, setVisible] = useState(false);

  return (
    <Box>
      <Box display={"flex"} marginBottom={1}>
        <Box width={150}>
          <Typography>Nama</Typography>
        </Box>
        <Typography>: {dataUser?.name}</Typography>
      </Box>
      <Box display={"flex"} marginBottom={1}>
        <Box width={150}>
          <Typography>Phone</Typography>
        </Box>
        <Typography>: {dataUser?.phone}</Typography>
      </Box>
      <Box display={"flex"} marginBottom={1}>
        <Box width={150}>
          <Typography>Email</Typography>
        </Box>
        <Typography>: {MaskingText(dataUser?.email || "")}</Typography>
      </Box>
      <Box display={"flex"} marginBottom={1}>
        <Box width={150}>
          <Typography>Username</Typography>
        </Box>
        <Typography>: {MaskingText(dataUser?.username || "")}</Typography>
      </Box>
      <Box marginTop={2}>
        <Button onClick={() => setVisible(true)} variant="contained">
          Ubah Password
        </Button>
      </Box>
      {dataUser?.id && (
        <Keamanan
          id={dataUser?.id}
          type={type}
          visible={visible}
          onClose={() => setVisible(false)}
        />
      )}
    </Box>
  );
}
