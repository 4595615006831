import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ToastStateContext } from "../../../context/toast/ToastContext";
import { HttpBaseRequest } from "../../../core/HttpRequest";
import { GetMysteryBox, PostMysteryBox } from "../MysteryBox.type";
import { GetMysteryBoxPrize, PostMysteryBoxPrize } from "./service";

export const usePostMysteryBoxPrize = () => {
  const query = useQueryClient();
  const toast = useContext(ToastStateContext);
  return useMutation({
    mutationKey: "post-mysterybox",
    mutationFn: ({ name, price, src, idMemberTier }: PostMysteryBox) =>
      PostMysteryBoxPrize({ name, price, src, idMemberTier }),
    onSuccess: () => {
      toast.show("Berhasil Menambahkan Hadiah Baru");
      query.invalidateQueries();
    },
  });
};

export const useGetMysteryBoxPrize = ({
  idMemberTier,
  enabledQuery,
  name,
}: HttpBaseRequest<GetMysteryBox>) => {
  return useQuery({
    queryKey: `mysterybox-${idMemberTier}-${name}`,
    queryFn: () => GetMysteryBoxPrize({ idMemberTier, name }),
    enabled: enabledQuery,
  });
};
