import { HttpBaseResponse } from "../../../core/HttpResponse";
import Http from "../../../core/http-axios-react/http/Http";
import { GetMysteryBox, IMysteryBox, PostMysteryBox } from "../MysteryBox.type";

export const PostMysteryBoxPrize = async ({
  name,
  price,
  src,
  idMemberTier,
}: PostMysteryBox) => {
  const results = await Http.post({
    url: "/mysterybox",
    data: {
      name,
      price,
      src,
      idMemberTier,
    },
    useFormMultiPart: true,
  });
  return results;
};

export const GetMysteryBoxPrize = async ({
  idMemberTier,
  name,
}: GetMysteryBox) => {
  const results = await Http.get<HttpBaseResponse<IMysteryBox[]>>({
    url: "/mysterybox",
    params: {
      idMemberTier,
      name,
    },
  });
  return results;
};
