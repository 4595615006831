import { useEffect } from "react";
import { useNavigate } from "react-router";
import { ENV_LISTS } from "../core/EnvTranspiler";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

interface IGuard {
  children: ReactJSXElement | ReactJSXElement[];
}

export default function Guard({ children }: IGuard) {
  const navigate = useNavigate();
  const key = ENV_LISTS.KEY_TOKEN;
  // Make sure auth is present
  useEffect(() => {
    const temp = localStorage.getItem(key);
    if (!temp) {
      navigate("/login");
    }
  }, []);

  return <>{children}</>;
}
