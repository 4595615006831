import { useMutation, useQuery } from "react-query";
import { getCustomerService, patchCustomerService, postCustomerService } from "./service";
import { useToast } from "../../../context/toast/ToastContext";
import { AxiosError } from "axios";
import { HttpBaseResponse } from "../../../core/HttpResponse";

export const useGetCustomerService = () => {
  return useQuery({
    queryKey: "customerService",
    queryFn: () => getCustomerService(),
  });
};

export const usePostCustomerService = () => {
  const toast = useToast();
  return useMutation({
    mutationKey: "postCustomerService",
    mutationFn: ({
      liveChat,
      waChat,
    }: {
      liveChat?: string;
      waChat?: string;
    }) => postCustomerService({ liveChat, waChat }),
    onSuccess: (e) => {
      toast.show("Berhasil Menambahkan link customer service");
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};

export const usePatchCustomerService = () => {
  const toast = useToast();
  return useMutation({
    mutationKey: "patchCustomerService",
    mutationFn: ({
      liveChat,
      waChat,
    }: {
      liveChat?: string;
      waChat?: string;
    }) => patchCustomerService({ liveChat, waChat }),
    onSuccess: (e) => {
      toast.show("Berhasil update link customer service");
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};
