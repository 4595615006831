import React, { useState } from "react";
import TableFC from "../../components/table/Table";
import { MainPadding } from "../../styles/Main.styles";
import { useUser } from "./network/resolver";
import { UserType } from "./type/user";
import TableHeader from "../../components/table/TableHeader";
import { Box } from "@mui/material";
import { InputSelect } from "../../components/input/input";
import { STATUS_USER, USER_TYPE } from "../../Constant";

export default function User() {
  const [showPage, setShowPage] = useState(1);
  const [usernameSearch, setUsernameSearch] = useState<string | undefined>(
    undefined
  );
  const [statusSearch, setStatusSearch] = useState(STATUS_USER.active.code);
  const [typeSearch, setTypeSearch] = useState(USER_TYPE.user.code);
  const { data: user } = useUser({
    page: showPage,
    username: usernameSearch,
    status: statusSearch,
    type: typeSearch,
  });
  const bodyTable = [
    { key: "name" },
    { key: "username" },
    { key: "email" },
    { key: "phone" },
  ];

  function handleOnClick(param: UserType) {
    window.open(`/user/${param.id}/${param.username}`);
  }

  function handleOnPageChange(index: number) {
    setShowPage(index);
  }

  if (!user) {
    return null;
  }

  return (
    <MainPadding>
      <TableHeader
        title="List User"
        filterLabel="Cari Username"
        searchBar
        onSearch={(e) => setUsernameSearch(e)}
        renderFilter={
          <Box marginTop={2} display={"flex"} gap={2}>
            <Box flexBasis={"50%"}>
              <InputSelect
                id="status-search"
                label="Status User"
                value={statusSearch}
                options={[
                  { text: "Active", value: STATUS_USER.active.code },
                  { text: "Suspend", value: STATUS_USER.suspend.code },
                ]}
                onChange={(e) => setStatusSearch(e)}
              />
            </Box>
            <Box flexBasis={"50%"}>
              <InputSelect
                id="type-search"
                label="Tipe User"
                value={typeSearch}
                options={[
                  { text: "Customer", value: USER_TYPE.user.code },
                  { text: "Admin", value: USER_TYPE.admin.code },
                ]}
                onChange={(e) => setTypeSearch(e)}
              />
            </Box>
          </Box>
        }
      />
      <TableFC
        showIndex
        totalRows={user.data.totalRows}
        data={user.data.data}
        body={bodyTable}
        head={["No", "Nama", "Username", "Email", "Phone"]}
        onPageChange={(e) => handleOnPageChange(e)}
        onCellClick={(e) => handleOnClick(e)}
      />
    </MainPadding>
  );
}
