import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import { useToast } from "../../../context/toast/ToastContext";
import { usePatchPassword } from "../network/resolver";

interface IKeamanan {
  id: number;
  type: "password" | "email";
  visible: boolean;
  onClose(): void;
}

export default function Keamanan({ id, type, visible, onClose }: IKeamanan) {
  const toast = useToast();
  const { mutate: mutatePassword, isLoading: loadingPassword } =
    usePatchPassword();
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [reNewPassword, setReNewPassword] = useState<string>("");

  function handleSubmitChangePassword() {
    if (newPassword !== reNewPassword) {
      toast.show("Password baru tidak sama");
      return;
    } else if (!newPassword || !reNewPassword || !oldPassword) {
      toast.show("mohon lengkapi informasi");
      return;
    }
    mutatePassword({ id, newPassword, oldPassword });
  }

  if (!visible) {
    return null;
  }

  return (
    <Box marginTop={2}>
      {type === "password" && (
        <Box display={"flex"} flexDirection={"column"}>
          <TextField
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            placeholder="Password Sekarang"
            margin="normal"
            type="password"
          />
          <TextField
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Password Baru"
            margin="normal"
            type="password"
          />
          <TextField
            value={reNewPassword}
            onChange={(e) => setReNewPassword(e.target.value)}
            placeholder="Ulangi Password Baru"
            margin="normal"
            type="password"
          />
          <Box
            marginTop={1}
            display={"flex"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <Button variant="contained" color="error" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmitChangePassword}>
              {loadingPassword ? (
                <CircularProgress style={{ color: "white" }} size={20} />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
