import { Box } from "@mui/material";
import { useState } from "react";
import Spinner from "../../components/spinner/Spinner";
import { MainPadding } from "../../styles/Main.styles";
import TierModal from "./components/TierModal";
import { useGetTier } from "./network/resolver";
import { TierTypeResponse } from "./Tier.type";
import TableFC from "../../components/table/Table";
import TableHeader from "../../components/table/TableHeader";

export default function Tier() {
  const { data: tierDataRaw, isFetching } = useGetTier();
  const tierData = tierDataRaw?.data.data;
  const [showModal, setModal] = useState(false);
  const [selected, setSelected] = useState<TierTypeResponse | undefined>(
    undefined
  );
  const bodyTable = [
    { key: "name" },
    { key: "minimum_spent", isMoney: true },
    { key: "point_convert_rate", isMoney: true },
    { key: "ticket_convert_rate" },
  ];

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <MainPadding>
      <Box>
        <TableHeader
          title="List Tingakatan Tier"
          addButton
          onClickAdd={() => {
            setSelected(undefined);
            setModal(true);
          }}
        />
        <TableFC
          showIndex
          data={tierData}
          head={[
            "No",
            "Nama",
            "Minimal Total TO",
            "TO per Point",
            "Ticket per Point",
          ]}
          body={bodyTable}
          onCellClick={(e) => {
            setSelected(e);
            setModal(true);
          }}
        />
      </Box>
      <TierModal
        dataTier={selected}
        show={showModal}
        onClose={() => setModal(false)}
      ></TierModal>
    </MainPadding>
  );
}
