import {
  Box,
  Card,
  TextField,
  Typography,
} from "@mui/material";
import { MainPadding } from "../../styles/Main.styles";
import { useState } from "react";
import { useToast } from "../../context/toast/ToastContext";
import { useAddUser } from "./network/resolver";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { InputSelect } from "../../components/input/input";
import { USER_TYPE } from "../../Constant";
import { LoadingButton } from "../../components/button/Button";

export default function UserAdmin() {
  const toast = useToast();
  const { mutate: mutateUser, isLoading: isLoadingMutateUser } = useAddUser();
  const [username, setUsernameName] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [passwordC, setPasswordC] = useState<string | undefined>(undefined);
  const [phone, setPhone] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [roleUserOptions] = useState([
    { text: "Customer", value: USER_TYPE.user },
    { text: "Admin", value: USER_TYPE.admin },
  ]);
  const [selectedRole, setSelectedRole] = useState<number>();

  function handleSubmit() {
    if (
      !username ||
      !name ||
      !password ||
      !passwordC ||
      !phone ||
      !email ||
      !selectedRole
    ) {
      toast.show("Mohon Lengkapi Informasi");
    } else if (password !== passwordC) {
      toast.show("Password tidak sama");
    } else {
      mutateUser({
        email,
        name,
        password,
        phone,
        username,
        role: selectedRole,
      });
    }
  }

  return (
    <MainPadding>
      <Card style={{ padding: 10 }}>
        <Box marginBottom={1}>
          <Typography variant="body1" fontWeight={"bold"}>
            Tambah Akun
          </Typography>
        </Box>
        <Box marginTop={3} marginBottom={1}>
          <InputSelect
            id={"role-user"}
            label="User Role"
            options={roleUserOptions}
            onChange={(e) => setSelectedRole(e.code)}
            value={selectedRole}
          />
        </Box>
        <TextField
          label="Name"
          placeholder="Name"
          fullWidth
          margin="dense"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <TextField
          label="Username"
          placeholder="Username"
          fullWidth
          margin="dense"
          onChange={(e) => setUsernameName(e.target.value)}
          value={username}
        />
        <TextField
          label="Password"
          placeholder="Password"
          fullWidth
          margin="dense"
          onChange={(e) => setPassword(e.target.value)}
          type="password"
        />
        <TextField
          label="Confirm Password"
          placeholder="Confirm Password"
          fullWidth
          margin="dense"
          onChange={(e) => setPasswordC(e.target.value)}
          type="password"
        />
        <TextField
          label="Phone"
          placeholder="Phone"
          fullWidth
          margin="dense"
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
        />
        <TextField
          label="Email"
          placeholder="Email"
          fullWidth
          margin="dense"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          type="email"
        />
        <Box paddingLeft={1} paddingTop={0.5} display={"flex"} gap={0.5}>
          <ErrorOutlineIcon style={{ fontSize: 18, color: "green" }} />
          <Typography variant="caption" color={"green"}>
            Mohon lakukan verifikasi Email setelah submit
          </Typography>
        </Box>
        <Box display="flex" justifyContent={"flex-end"} marginTop={1}>
          <LoadingButton
            onClick={handleSubmit}
            text="Submit"
            isLoading={isLoadingMutateUser}
          />
        </Box>
      </Card>
    </MainPadding>
  );
}
