import { HttpBaseRequest } from "../../../core/HttpRequest";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import Http from "../../../core/http-axios-react/http/Http";
import {
  AddTurnOverType,
  GetHistoryPlayType,
  GetHistoryPurchase,
  GetTurnOverType,
  GetTurnOverTypeRequest,
  PatchUserType,
  PatchUserTypeResponse,
  UserProfileType,
  UserType,
} from "../type/user";

export const getProfile = async () => {
  const result = await Http.get({
    url: "/users/profile",
  });
  return result;
};

export const getUser = async ({
  page,
  username,
  status,
  type,
}: {
  page: number;
  username?: string;
  status: number;
  type: number;
}) => {
  const results = await Http.get<HttpBaseResponse<UserType[]>>({
    url: "/users",
    params: {
      page,
      username,
      type,
      status,
    },
  });
  return results;
};

export const addUser = async ({
  name,
  username,
  password,
  phone,
  email,
  role,
}: {
  name: string;
  username: string;
  password: string;
  phone: string;
  email: string;
  role: number;
}) => {
  const results = await Http.post({
    url: "/users",
    data: {
      name,
      username,
      password,
      phone,
      email,
      type: role,
    },
  });
  return results;
};

export const getUserProfile = async ({ id }: { id?: number }) => {
  const results = await Http.get<HttpBaseResponse<UserProfileType>>({
    url: "/users/profile",
    params: {
      id,
    },
  });
  return results;
};

export const postAddTurnOver = async ({
  username,
  amount,
  files,
}: {
  username?: string;
  amount?: number;
  files?: any;
}) => {
  const results = await Http.post<HttpBaseResponse<AddTurnOverType>>({
    url: "/turnover",
    useFormMultiPart: true,
    data: {
      username,
      amount,
      files,
    },
  });
  return results;
};

export const getTurnOver = async ({
  idUser,
  page,
}: HttpBaseRequest<GetTurnOverTypeRequest>) => {
  const results = await Http.get<HttpBaseResponse<GetTurnOverType[]>>({
    url: "/turnover",
    params: {
      page,
      idUser,
    },
  });
  return results;
};

export const patchUser = async ({
  username,
  email,
  bank_acc_name,
  bank_acc_no,
  phone,
}: HttpBaseRequest<PatchUserType>) => {
  const results = await Http.put<HttpBaseResponse<PatchUserTypeResponse>>({
    url: "/users/admin",
    data: {
      username,
      email,
      bank_acc_name,
      bank_acc_no,
      phone,
    },
  });
  return results;
};

export const getHistoryPlay = async ({
  idUser,
  page,
}: {
  idUser: number;
  page: number;
}) => {
  const results = await Http.get<HttpBaseResponse<GetHistoryPlayType[]>>({
    url: "/mysterybox/prize/history",
    params: {
      idUser,
      page,
    },
  });
  return results;
};

export const getHistoryPurchase = async ({
  idUser,
  page,
  name,
  type,
}: {
  idUser?: number;
  page?: number;
  name?: string;
  type?: number;
}) => {
  const results = await Http.get<HttpBaseResponse<GetHistoryPurchase[]>>({
    url: "/transaction",
    params: {
      idUser,
      page,
      name,
      statusCode: type,
    },
  });
  return results;
};

export const patchHistoryPurchase = async ({
  id,
  status,
}: {
  id: number;
  status: number;
}) => {
  const results = await Http.put({
    url: "/transaction",
    data: {
      id,
      status,
    },
  });
  return results;
};
