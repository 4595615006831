import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import { usePreLogin } from "../network/resolver";
import { useToast } from "../../../context/toast/ToastContext";
import RequestOtp from "./RequestOtp";
import { LoadingButton } from "../../../components/button/Button";

export default function PreLogin() {
  const toast = useToast();
  const { mutateAsync: mutatePreLogin, isLoading: loadingPreLogin } =
    usePreLogin();
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [showOtp, setShowOtp] = useState(false);

  function doLogin() {
    if (!username || !password) {
      toast.show("Mohon lengkapi field");
      return;
    }
    mutatePreLogin({ username, password })
      .then((res) => setShowOtp(true))
      .catch((err) => console.log(err));
  }

  return (
    <Box>
      {showOtp ? (
        <RequestOtp />
      ) : (
        <Box>
          <Box display={"flex"} flexDirection={"column"}>
            <TextField
              label="Username"
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
              margin="normal"
            />
            <TextField
              margin="normal"
              label="Password"
              placeholder="Password"
              type={"password"}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Box>
          <Box marginTop={2} display={"flex"} justifyContent={"flex-end"}>
            <LoadingButton
              text="Login"
              onClick={doLogin}
              isLoading={loadingPreLogin}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
