import { Box } from "@mui/material";
import { MainPadding } from "../../styles/Main.styles";
import { useGetHistoryPurchase } from "../user/network/resolver";
import { useState } from "react";
import TableFC from "../../components/table/Table";
import TableHeader from "../../components/table/TableHeader";
import ModalUpdateStatus from "./components/ModalUpdateStatus";
import { GetHistoryPurchase } from "../user/type/user";
import { InputSelect } from "../../components/input/input";
import { HISTORY_STATUS } from "../../Constant";

export default function ProsesHadiah() {
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState<GetHistoryPurchase>();
  const [showModal, setShowModal] = useState(false);
  const [typeSearch, setTypeSearch] = useState<number | undefined>(HISTORY_STATUS.waiting);
  const [nameSearch, setNameSearch] = useState<string | undefined>(undefined);
  const { data: dataHistoryPurcaseRaw } = useGetHistoryPurchase({
    enabled: true,
    page,
    name: nameSearch,
    type: typeSearch,
  });
  const dataHistory = dataHistoryPurcaseRaw?.data.data;
  const dataHistoryRows = dataHistoryPurcaseRaw?.data.totalRows;
  const bodyTable = [
    { key: "name" },
    { key: "nameUser" },
    { key: "price", isMoney: true },
    { key: "statusText" },
    { key: "createdAt", isDate: true },
    { key: "img", isImage: true },
  ];

  const FilterRight = () => {
    const status = [
      { text: "Ditolak", value: HISTORY_STATUS.rejected },
      { text: "Menunggu Konfirmasi", value: HISTORY_STATUS.waiting },
      { text: "Diproses", value: HISTORY_STATUS.process },
      { text: "Dikirim", value: HISTORY_STATUS.sending },
      { text: "Selesai", value: HISTORY_STATUS.completed },
    ];
    return (
      <Box maxWidth={250} marginTop={2}>
        <InputSelect
          id="status"
          label="Status"
          options={status as any}
          onChange={(e) => {
            setTypeSearch(e)
          }}
          value={typeSearch}
        />
      </Box>
    );
  };

  return (
    <MainPadding>
      <Box>
        <TableHeader
          title="List Proses Hadiah"
          searchBar
          filterLabel="Cari Nama User"
          renderFilter={<FilterRight />}
          onSearch={(e) => {
            setPage(1);
            setNameSearch(e);
          }}
        />
        <TableFC
          showIndex
          body={bodyTable}
          data={dataHistory}
          totalRows={dataHistoryRows}
          head={[
            "No",
            "Nama Hadiah",
            "Nama User",
            "Harga",
            "Status",
            "Tanggal",
            "Gambar",
          ]}
          onPageChange={(e) => setPage(e)}
          onCellClick={(e) => {
            setSelected(e);
            setShowModal(true);
          }}
        />
      </Box>
      <ModalUpdateStatus
        show={showModal}
        onClose={() => setShowModal(false)}
        selectedStatus={selected}
      />
    </MainPadding>
  );
}
