import { HttpBaseResponse } from "../../../core/HttpResponse";
import Http from "../../../core/http-axios-react/http/Http";
import { DesignRequest, DesignResponse } from "../Design.type";

export const getDesign = async () => {
  const results = await Http.get<HttpBaseResponse<DesignResponse>>({
    url: "/design",
  });
  return results;
};

export const patchDesign = async ({
  coverType,
  coverSrcBlob,
  logo,
  runningText,
  thumbnails,
  videoUrl,
}: DesignRequest) => {
  const results = await Http.put<HttpBaseResponse<any>>({
    url: "/design",
    data: {
      coverType,
      coverSrcBlob,
      logo: logo,
      runningText,
      thumbnails,
      videoUrl,
    },
    useFormMultiPart: true,
  });
  return results;
};

export const patchThumbnail = async ({
  id,
  index,
  img,
}: {
  id: number;
  index: number;
  img: File;
}) => {
  const results = await Http.put<HttpBaseResponse<any>>({
    url: "/design/thumbnail",
    data: {
      id,
      index,
      img,
    },
    useFormMultiPart: true,
  });
  return results;
};
