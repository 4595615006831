import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ButtonContainer, MainPadding } from "../../../styles/Main.styles";
import { useGetTier, usePatchTier } from "../../tier/network/resolver";
import ModalAddMysteryBox from "../components/ModalAdd";
import { useGetMysteryBoxPrize } from "../network/resolver";
import TableFC from "../../../components/table/Table";
import TableHeader from "../../../components/table/TableHeader";

export default function MysteryBox() {
  const { data: dataTierRaw } = useGetTier();
  const dataTier = dataTierRaw?.data.data;
  const [selectedTier, setSelectedTier] = useState<number>(-1);
  const [showTierModal, setShowTierModal] = useState(false);
  const [nameSearch, setNameSearch] = useState<string | undefined>(undefined);
  const { data: dataMysteryBoxRaw } = useGetMysteryBoxPrize({
    idMemberTier: selectedTier,
    enabledQuery: selectedTier !== -1,
    name: nameSearch,
  });
  const dataMysteryBox = dataMysteryBoxRaw?.data.data;
  const [to, setTo] = useState<number>(0);
  const [probability, setProbability] = useState(50);
  const headerTable = ["No", "Nama", "Harga", "Gambar"];
  const bodyTable = [
    { key: "name" },
    { key: "price", isMoney: true },
    { key: "src", isImage: true },
  ];
  const { mutate: mutateTier, isLoading: loadingPatchTier } = usePatchTier();

  useEffect(() => {
    if (dataTier) {
      const selected = dataTier[0];
      setTo(selected.max_prize_convert || to);
      setProbability(selected.probability || 50);
      setSelectedTier(selected.id);
    }
  }, [dataTier]);

  function handleOnDropdownChange(value: number) {
    if (dataTier) {
      const index = dataTier.findIndex((item) => item.id === value);
      setSelectedTier(value);
      setTo(dataTier[index]?.max_prize_convert || to);
      setProbability(dataTier[index]?.probability || 50);
    }
  }

  function handleUpdate() {
    if (dataTier) {
      const selected = dataTier[0];
      mutateTier({
        id: selected.id,
        maxPrizeConvert: to,
        probability,
      });
    }
  }

  if (!dataTier) {
    return null;
  }

  return (
    <MainPadding>
      <Card sx={{ marginBottom: 2 }}>
        <CardContent>
          <Box marginBottom={2}>
            <Typography variant="h6" fontWeight={"bold"}>
              Setting Probabilitas
            </Typography>
          </Box>
          <div>
            <Select
              style={{ minWidth: 300 }}
              size="medium"
              label="Member Tier"
              value={selectedTier}
              onChange={(e: any) => handleOnDropdownChange(e.target.value)}
            >
              {dataTier &&
                dataTier.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
          <Box display={"flex"} marginTop={4} gap={3}>
            <Box flexBasis={"50%"}>
              <TextField
                type={"number"}
                value={to}
                onChange={(e) => {
                  const value = parseFloat(e.target.value) || 0;
                  setTo(value);
                }}
                fullWidth
                label="Jumlah Max Hadiah Berdasarkan Total TO"
              />
            </Box>
            <Box flexBasis={"50%"}>
              <TextField
                value={probability}
                onChange={(e) => {
                  const value = parseInt(e.target.value) || 0;
                  setProbability(value);
                }}
                fullWidth
                label="Kemungkinan User Menang (%)"
              />
            </Box>
          </Box>
          <ButtonContainer style={{ marginTop: 10 }}>
            <Button variant="contained" onClick={handleUpdate}>
              {loadingPatchTier ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                "Update"
              )}
            </Button>
          </ButtonContainer>
        </CardContent>
      </Card>
      <Box>
        <TableHeader
          title="List Hadiah Mysterybox"
          filterLabel="Cari Hadiah"
          searchBar
          addButton
          onSearch={(e) => {
            setNameSearch(e);
          }}
          onClickAdd={() => setShowTierModal(true)}
        />
        <TableFC
          showIndex
          head={headerTable}
          body={bodyTable}
          data={dataMysteryBox}
        />
      </Box>
      <ModalAddMysteryBox
        tier={selectedTier}
        onClose={() => setShowTierModal(false)}
        show={showTierModal}
      />
    </MainPadding>
  );
}
