import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import TableFC from "../../components/table/Table";
import { MainPadding } from "../../styles/Main.styles";
import ModalAddPrize from "./components/ModalAddPrize";
import { useGetPrize } from "./network/resolver";
import { Container } from "./Prize.styles";
import { PrizeResponse } from "./Prize.type";
import TableHeader from "../../components/table/TableHeader";

export default function Prize() {
  const [nameSearch, setNameSearch] = useState<string | undefined>(undefined);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [selectedPrize, setSelectedPrize] = useState<PrizeResponse | null>(
    null
  );
  const [page, setPage] = useState(1);
  const { data: dataPrizeRaw, isLoading } = useGetPrize({
    page,
    name: nameSearch,
    enabledQuery: true,
  });
  const dataPrize = dataPrizeRaw?.data.data;
  const bodyTable = [
    { key: "name" },
    { key: "price", isMoney: true },
    { key: "img", isImage: true },
  ];

  function handleClick(prize: PrizeResponse) {
    setSelectedPrize(prize);
    setShowModalAdd(true);
  }

  return (
    <MainPadding>
      <Container>
        <TableHeader
          title={"List Penukaran Hadiah"}
          addButton
          searchBar
          filterLabel="Cari Nama Hadiah"
          onSearch={(e) => setNameSearch(e)}
          onClickAdd={() => {
            setShowModalAdd(true);
            setSelectedPrize(null);
          }}
        />
        <TableFC
          data={dataPrize}
          body={bodyTable}
          showIndex
          perColumns={10}
          totalRows={dataPrizeRaw?.data.totalRows}
          head={["No", "Nama", "Harga", "Gambar"]}
          onCellClick={(e) => handleClick(e)}
          onPageChange={(e) => setPage(e)}
        />
        <ModalAddPrize
          prize={selectedPrize}
          visible={showModalAdd}
          onClose={() => setShowModalAdd(false)}
        />
      </Container>
    </MainPadding>
  );
}
