import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ToMoneyFormat } from "../../utils/Helper";

interface InputMoneyProps {
  label: string;
  value?: number;
  onChange(value: number): void;
}

export interface IOptions {
  text: string;
  value: any
}

const InputMoney = ({ value, onChange, label }: InputMoneyProps) => {
  const [amount, setAmount] = useState<string>('');

  useEffect(() => {
    if (value) {
      const target = value.toString().replaceAll(".", "");
      const temp = ToMoneyFormat(target);
      setAmount(temp || "");
    } else {
      setAmount('')
    }
  },[value])

  function handleOnChage(e: string) {
    const target = e.replaceAll(".", "");
    const temp = ToMoneyFormat(target);
    setAmount(temp || "");
    onChange(target ? parseInt(target) : 0);
  }

  return (
    <TextField
      value={amount}
      label={label}
      fullWidth
      onChange={(e) => handleOnChage(e.target.value)}
    />
  );
};

const InputSelect = ({
  id,
  label,
  value,
  options,
  onChange,
}: {
  id: string | number;
  label: string;
  value: any;
  options: IOptions[];
  onChange(item: any): void;
}) => {
  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel id={`select-input-${id}`}>{label}</InputLabel>
      <Select
        value={value}
        labelId={`select-input-${id}`}
        id={`select-input-${id}`}
        fullWidth
        label="Status Hadiah"
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((item, index) => {
          return (
            <MenuItem key={index} value={item.value}>
              {item.text}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export { InputMoney, InputSelect };
