import Http from "../../../core/http-axios-react/http/Http";

export const patchPassword = async ({
  id,
  oldPassword,
  newPassword,
}: {
  id: number;
  oldPassword: string;
  newPassword: string;
}) => {
  const result = await Http.put({
    url: "/users/password",
    data: {
      id,
      oldPassword,
      newPassword
    }
  });
  return result
};
