import { useMutation } from "react-query";
import { patchPassword } from "./service";
import { useToast } from "../../../context/toast/ToastContext";
import { AxiosError } from "axios";
import { HttpBaseResponse } from "../../../core/HttpResponse";

export const usePatchPassword = () => {
  const toast = useToast();

  return useMutation({
    mutationKey: "patchPassword",
    mutationFn: ({
      id,
      oldPassword,
      newPassword,
    }: {
      id: number;
      oldPassword: string;
      newPassword: string;
    }) => patchPassword({ id, oldPassword, newPassword }),
    onSuccess: () => {
      toast.show("Berhasil update password");
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};
