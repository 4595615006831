import { useQuery } from "react-query";
import { getUserInformation } from "./service";

export const useGetUserInformation = ({ enabled }: { enabled: boolean }) => {
  return useQuery({
    queryKey: "userProfile",
    queryFn: () => getUserInformation(),
    enabled,
  });
};
