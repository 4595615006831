import { useMutation, useQuery, useQueryClient } from "react-query";
import { useToast } from "../../../context/toast/ToastContext";
import { TierTypeRequest } from "../Tier.type";
import { getTier, patchTier, postTier } from "./service";
import { AxiosError } from "axios";
import { HttpBaseResponse } from "../../../core/HttpResponse";

export const useGetTier = () => {
  return useQuery({
    queryFn: async () => await getTier({}),
  });
};

export const usePostTier = () => {
  const query = useQueryClient();
  const toast = useToast();
  return useMutation({
    mutationFn: ({
      minimumSpent,
      name,
      maxPrizeConvert,
      pointConvertRate,
      ticketConvertRate,
      probability,
    }: TierTypeRequest) =>
      postTier({
        minimumSpent,
        name,
        maxPrizeConvert,
        pointConvertRate,
        ticketConvertRate,
        probability,
      }),
    onSuccess: () => {
      toast.show("Berhasil Menambahkan turnover");
      query.invalidateQueries();
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};

export const usePatchTier = () => {
  const query = useQueryClient();
  const toast = useToast();
  return useMutation({
    mutationFn: ({
      minimumSpent,
      name,
      maxPrizeConvert,
      pointConvertRate,
      ticketConvertRate,
      probability,
      id,
    }: TierTypeRequest) =>
      patchTier({
        minimumSpent,
        name,
        maxPrizeConvert,
        pointConvertRate,
        ticketConvertRate,
        probability,
        id,
      }),
    onSuccess: () => {
      toast.show("Berhasil Update turnover");
      window.location.reload()
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};
