import { Box, Card, Typography } from "@mui/material";
import { MainPadding } from "../../styles/Main.styles";
import { LoadingButton } from "../../components/button/Button";
import { useDoBackup, useDownloadBackup } from "./network/resolver";
import { useState } from "react";
import { useToast } from "../../context/toast/ToastContext";
import { ENV_LISTS } from "../../core/EnvTranspiler";

export default function BackupDatabase() {
  const toast = useToast();
  const { mutateAsync: mutateBackup, isLoading: loadingBackup } = useDoBackup();
  const { mutate: mutateDownload, isLoading: loadingDownload } =
    useDownloadBackup();
  const [loading, setLoading] = useState(false);

  function downloadFile(path: string) {
    const fileUrl = `${ENV_LISTS.API_URL}/backup/download?path=${path}`;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = path;
    link.click();
  }

  function handleBackup() {
    setLoading(true);
    mutateBackup()
      .then(async (res) => {
        const pathToDownload = res.data.data.path;
        // Donwload file
        setTimeout(() => {
          downloadFile(pathToDownload);
          setLoading(false);
        }, 3000);
      })
      .catch((err) => {
        toast.show(
          "Tidak dapat melakukan backup database, mohon menghubungi admin"
        );
        setLoading(false);
      });
  }

  return (
    <MainPadding>
      <Card>
        <Box
          padding={2}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Box marginBottom={2}>
            <Typography>
              Lakukan backup dan download database dalam format .sql
            </Typography>
          </Box>
          <LoadingButton
            text="Backup Database"
            onClick={handleBackup}
            isLoading={loading}
          />
        </Box>
      </Card>
    </MainPadding>
  );
}
