import { HttpBaseResponse } from "../../../core/HttpResponse";
import Http from "../../../core/http-axios-react/http/Http";
import IUserInformation from "../UserInformation.type";

export const getUserInformation = async () => {
  const result = await Http.get<HttpBaseResponse<IUserInformation>>({
    url: "/users/profile",
  });
  return result;
};
