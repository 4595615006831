import { Box, Button, Card, TextField } from "@mui/material";
import { MainPadding } from "../../styles/Main.styles";
import { useEffect, useState } from "react";
import {
  useGetCustomerService,
  usePatchCustomerService,
} from "./network/resolver";
import { LoadingButton } from "../../components/button/Button";

export default function CustomerService() {
  const { data: dataRawCustomerService, isLoading: loadingFetch } =
    useGetCustomerService();
  const dataCustomerService = dataRawCustomerService?.data.data;
  const { mutate: mutatePatch, isLoading: loadingMutate } = usePatchCustomerService();
  const [liveChat, setLiveChat] = useState("");
  const [waChat, setWaChat] = useState("");

  useEffect(() => {
    if (dataCustomerService) {
      dataCustomerService.forEach((item) => {
        if (item.name === "livechat") {
          setLiveChat(item.url);
        } else if (item.name === "wachat") {
          setWaChat(item.url);
        }
      });
    }
  }, [dataCustomerService]);

  function handleSubmit() {
    mutatePatch({
      liveChat,
      waChat,
    });
  }

  if (loadingFetch) {
    return null;
  }

  return (
    <MainPadding>
      <Card>
        <Box padding={2} display={"flex"} flexDirection={"column"}>
          <TextField
            value={liveChat}
            onChange={(e) => setLiveChat(e.target.value)}
            placeholder="Live Chat"
            label="Live Chat"
            margin="normal"
          />
          <TextField
            value={waChat}
            onChange={(e) => setWaChat(e.target.value)}
            placeholder="WA Chat"
            label="WA chat"
            margin="normal"
          />
          <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
            <LoadingButton
              text="Submit"
              isLoading={loadingMutate}
              onClick={handleSubmit}
            />
          </Box>
        </Box>
      </Card>
    </MainPadding>
  );
}
