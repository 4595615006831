import styled from "@emotion/styled";

const ContainerProfile = styled.div`
  margin-top: 20px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export { ContainerProfile, ButtonContainer }