import { HttpBaseRequest } from "../../../core/HttpRequest";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import Http from "../../../core/http-axios-react/http/Http";
import { PrizeRequest, PrizeResponse } from "../Prize.type";

export const getPrize = async ({
  page,
  name,
}: HttpBaseRequest<PrizeRequest>) => {
  const results = await Http.get<HttpBaseResponse<PrizeResponse[]>>({
    url: "/prize",
    params: {
      page,
      name,
    },
  });
  return results;
};

export const addPrize = async ({
  price,
  name,
  img,
}: {
  price: number;
  name: string;
  img?: File;
}) => {
  const results = await Http.post({
    useFormMultiPart: true,
    url: "/prize",
    data: {
      price,
      name,
      img: img,
    },
  });

  return results;
};

export const updatePrize = async ({
  id,
  price,
  name,
  img,
}: {
  id: number;
  idMemberTier?: number;
  price?: number;
  name?: string;
  img?: File;
}) => {
  const results = await Http.put({
    url: "/prize",
    useFormMultiPart: true,
    data: {
      id,
      price,
      name,
      img,
    },
  });
  return results;
};

export const deletePrize = async ({ id }: { id: number }) => {
  const result = await Http.post({
    url: "/prize/delete",
    data: {
      id,
    },
  });
};
