import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { InputMoney } from "../../../components/input/input";
import UploadImage from "../../../components/input/UploadImage";
import {
  MainContainerModal,
  MainContainerWrapper,
  ButtonContainer,
} from "../../../styles/Main.styles";
import { useAddPrize, usePatchPrize } from "../../hadiah/network/resolver";
import { PrizeResponse } from "../../hadiah/Prize.type";
import { TierTypeResponse } from "../../tier/Tier.type";
import { usePostMysteryBoxPrize } from "../network/resolver";

interface ModalAddPrizeProps {
  tier: number;
  prize?: PrizeResponse;
  show: boolean;
  onClose(): void;
}

export default function ModalAddMysteryBox({
  tier,
  prize,
  show,
  onClose,
}: ModalAddPrizeProps) {
  const { mutate: mutatePrizeBox } = usePostMysteryBoxPrize();
  const [name, setName] = useState("");
  const [price, setPrice] = useState<number>();
  const [img, setImg] = useState<string>();
  const [fileImg, setFileImg] = useState<File>();

  useEffect(() => {
    if (prize) {
      setName(prize.name);
      setPrice(prize.price);
      setImg(prize.img || "");
    }
  }, [prize]);

  function handleOnUpload(file: FileList) {
    if (file.length > 0) {
      setFileImg(file[0]);
    }
  }

  async function handleSubmit() {
    const priceToSet = price || 0;
    // Add Prize
    if (fileImg) {
      mutatePrizeBox({
        name,
        idMemberTier: tier,
        price: priceToSet,
        src: fileImg,
      });
    }
  }

  return (
    <Modal open={show} onClose={onClose}>
      <MainContainerModal>
        <MainContainerWrapper style={{ minWidth: 200 }}>
          <Box>
            <Typography variant="body1" fontWeight={"bold"}>
              Tambah Hadiah Mysterybox
            </Typography>
          </Box>
          <TextField
            fullWidth
            label="Nama"
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <InputMoney
            label="Harga"
            value={price}
            onChange={(e) => setPrice(e)}
          />
          <UploadImage
            id="modal-add-prize"
            defaultView={img}
            onUpload={(e) => handleOnUpload(e)}
          />
          <ButtonContainer>
            <Button onClick={handleSubmit} variant="contained">
              Submit
            </Button>
          </ButtonContainer>
        </MainContainerWrapper>
      </MainContainerModal>
    </Modal>
  );
}
