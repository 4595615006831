export const FromStringToDate = (dateParam: string) => {
  const tempDateString = dateParam.split("T");
  let temp = new Date(tempDateString[0]);
  return `${temp.getDate()}-${temp.getMonth() + 1}-${temp.getFullYear()}`;
};

export const FromStringToDateTime = (dateParam: string) => {
  if (!dateParam) {
    return "";
  }
  const tempDateString = dateParam.split("T");
  // Handle Split Time
  const tempDateTimeString = tempDateString[1].split(":");
  const H = tempDateTimeString[0];
  const M = tempDateTimeString[1];
  const S = 0;
  // Parse Back to date
  let temp = new Date(`${tempDateString[0]} ${H}:${M}:${S}`);
  return `${temp.getDate().toString().padStart(2, "0")}-${(temp.getMonth() + 1)
    .toString()
    .padStart(
      2,
      "0"
    )}-${temp.getFullYear()}  ${temp.getHours()}:${temp.getMinutes()}`;
};

export const CreditToString = (credit: string) => {
  let temp = credit.split(".")[0];
  temp = temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (temp[0] === "-") {
    temp = temp.substr(1, temp.length);
    return `-Rp ${temp}`;
  }
  return `+Rp ${temp}`;
};

export const ToMoneyFormat = (credit: string | number | undefined) => {
  if (!credit) {
    return 0;
  }
  const temp = credit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return temp;
};

export const MaskingText = (text: string) => {
  if (!text) {
    return text;
  }
  return `***${text.substring(5)}`;
};