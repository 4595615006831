import { HttpBaseResponse } from "../../../core/HttpResponse";
import Http from "../../../core/http-axios-react/http/Http";
import { IBackupDatabase } from "../BackupDatabase.type";

export const doBackup = async () => {
  const result = await Http.post<HttpBaseResponse<IBackupDatabase>>({
    url: "/backup",
  });
  return result;
};

export const doDownloadBackup = async ({ path }: { path: string }) => {
  const result = await Http.get({
    url: "/backup/download",
    params: {
      path,
    },
  });
  return result;
};
