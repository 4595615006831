import { Box } from "@mui/system";
import TableFC from "../../../../components/table/Table";
import { useGetHistoryPlay } from "../../network/resolver";
import TableHeader from "../../../../components/table/TableHeader";
import { useState } from "react";

interface PlayHistoryProps {
  idUser: number;
}

export default function PlayHistory({ idUser }: PlayHistoryProps) {
  const [page, setPage] = useState(1)
  const { data: dataRawPlayHistory, isLoading } = useGetHistoryPlay({
    idUser,
    page,
    enabled: idUser !== undefined,
  });
  const dataPlayHistoryRows = dataRawPlayHistory?.data.totalRows
  const dataPlayHistory = dataRawPlayHistory?.data.data;
  const bodyTable = [
    { key: "name" },
    { key: "price", isMoney: true },
    { key: "created_at", isDate: true },
    { key: "img", isImage: true },
  ];

  return (
    <Box>
      <TableHeader title="List History Permainan" />
      <TableFC
        showIndex
        totalRows={dataPlayHistoryRows}
        data={dataPlayHistory}
        body={bodyTable}
        head={["No", "Nama", "Harga", "Tanggal", "Gambar"]}
        perColumns={5}
        onPageChange={(e) => setPage(e)}
        // onCellClick={(e) => handleOnClick(e)}
      />
    </Box>
  );
}
