import styled from "@emotion/styled";

export const Container = styled.div`
  margin-top: 20px;
`

export const PrizeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`